import { Injectable } from "@angular/core";
import { Listing } from "./listing";
import { ListingDetail } from "./listing-detail";

@Injectable()
export class Globals {
  hostnameStr: string = "";
  siteIDStr: number = 3069;
  linksEnabledBln: boolean = false;
  emailStr: string = "";
  userIDStr: string = "";
  savedSearchIDStr: string = "";
  ipAddrStr: string = "";
  sidStr: string = "";
  isRegisteredBln: string = "0";
  initBln: boolean = true;
  loginBln: boolean = true;
  debugBln: boolean = false;
  loadingBln: boolean = false;
  timeoutNum: number = 0;
  delayObj: Object = {};
  initLoadCompleteBln: boolean = true;
  limitNum: number = 10;
  startNum: number = 0;
  endNum: number = 10;
  maxPreviewNum: number = 2;
  viewedNum: number = 0;
  scrollerObj: Object = {};
  statusStr: string = '"Active", "Sold"';
  stateStr: string = "ON";
  idStr: number = 3069;
  countNum: number = 0;
  propertyTypeStr: string = "Not Specified";
  districtList: string = "ALL OF MUSKOKA";
  searchMin: string = "125000";
  searchMax: string = "100000000";
  bedroomsNum: string = "Any";
  bathroomsNum: string = "Any";
  squareFeetStr: string = "Any";
  listingsViewedNum: number = 0;
  listings: Listing[] = [];
  selectedListing: ListingDetail | undefined;
  routeScrollPositions: { [url: string]: number } = {};
  scrollTopNum: number = 0;
  mobileBln: boolean = false;
  previousURLStr: string = "";
  previousStatusStr: string = "";
  enableExclusiveListingsBln: boolean = true;
}
