import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";

import { Listing } from "./listing";

@Injectable()
export class TorontoListingsSearchService {
  private listingsUrlStr = "/includes/rets/toronto/REP_RETSSearch.php"; // URL to web api

  constructor(private http: HttpClient) {}

  search(term: string): any {
    return this.http.get(this.listingsUrlStr + `?name=${term}`).pipe(
      map((r: any) => r.data as Listing[]),
      catchError(this.handleError),
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error("Something bad happened; please try again later."),
    );
  }
}
