import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Globals } from "./globals";

import { BsModalRef } from "ngx-bootstrap/modal";

/* This is a component which we pass in modal*/

@Component({
  selector: "modal-content",
  styles: [],
  templateUrl: "./modal-content.component.html",
})
export class ModalContentComponent implements OnInit {
  public title: string = "";

  public isRegisteredBln: boolean = false;

  private searchURlStr = "/includes/classes/REP_SearchProcess.php"; // URL to web api

  public errorStr: string = "";

  public step2Bln: boolean = false;
  model = {
    propertyType: { value: "Not Specified" },
    area: { value: "" },
    priceRangeMin: { value: "1000000" },
    priceRangeMax: { value: "100000000" },
    bedrooms: { value: "Any" },
    bathrooms: { value: "Any" },
    lotSize: { value: "Any" },
    squareFeet: { value: "Any" },
    yearBuilt: { value: "All" },
    firstName: { value: "" },
    lastName: { value: "" },
    phoneNumber: { value: "" },
    emailAddress: { value: "" },
  };
  public uidStr: string = "";

  private captchaResponseStr: string = "";
  string = "";

  public submitBln: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    public globals: Globals,
  ) {
    var cityStr = globals.districtList;
    if (cityStr != "") this.model.area.value = cityStr;
  }

  ngOnInit() {}

  public submitForm(id: number) {
    switch (id) {
      case 1:
        // show the second part of the search form
        this.step2Bln = true;
        break;
      case 2:
        console.log("hello");
        //this.bsModalRef.hide();
        console.log("closing");
    }
  }

  public submitInitialData(captchaResponse: string) {
    //console.log(captchaResponse);
    // set the submit boolean to indicate that work is being done
    this.submitBln = true;
    // submit the search data
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.setupInitialJSONObject({
        _captchaResponse: captchaResponse,
      }),
    };
    this.captchaResponseStr = captchaResponse;
    let paramsObj = this.setupInitialJSONObject({
      _captchaResponse: captchaResponse,
    });
    let params = new HttpParams({
      fromObject: paramsObj,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    this.http.post(this.searchURlStr, params.toString(), httpOptions).subscribe(
      (response) => {
        // re-enable the submit buttons
        this.submitBln = false;
        //console.log(response.json());
        // define properties
        var responseObj = response as any;
        var statusBln = responseObj.statusBln;
        var uidStr = responseObj.uidStr;
        // fork, if there was an error then output the error
        if (!statusBln) {
          this.errorStr = responseObj.errorArr[0];
          // reset the recaptcha
          let myWindow: any = window;
          myWindow.grecaptcha.reset();
          this.captchaResponseStr = "";
        }
        if (statusBln) {
          // create the cookie data object
          let d: Date = new Date();
          d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
          let expires: string = `expires=${d.toUTCString()}`;
          console.log(responseObj);
          document.cookie =
            "containerObj=" +
            responseObj.cookieStr +
            "; " +
            expires +
            "; path=/";
          // update the user id string for the second part of the form tracking
          this.uidStr = uidStr;
          // show the second part of the search form
          this.step2Bln = true;
          //this.bsModalRef.hide();
          //this.globals.isRegisteredBln="1";
          //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
        }
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public submitData() {
    //console.log(captchaResponse);
    // set the submit boolean to indicate that work is being done
    this.submitBln = true;
    // submit the search data
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.setupJSONObject(),
    };
    let paramsObj = this.setupJSONObject();
    let params = new HttpParams({
      fromObject: paramsObj,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    // track the even
    this.http.post(this.searchURlStr, params.toString(), httpOptions).subscribe(
      (response) => {
        // re-enable the submit buttons
        this.submitBln = false;
        //console.log(response.json());
        // define properties
        var responseObj = response as any;
        var statusBln = responseObj.statusBln;
        // fork, if there was an error then output the error
        if (!statusBln) {
          this.errorStr = responseObj.errorArr[0];
          // reset the recaptcha
          //let myWindow: any = window;
          // myWindow.grecaptcha.reset();
        }
        if (statusBln) {
          // create the cookie data object
          let d: Date = new Date();
          d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
          let expires: string = `expires=${d.toUTCString()}`;
          console.log(responseObj);
          document.cookie =
            "containerObj=" +
            responseObj.cookieStr +
            "; " +
            expires +
            "; path=/";
          this.bsModalRef.hide();
          this.globals.isRegisteredBln = "1";
          this.globals.userIDStr = responseObj.userIDStr;
          console.log("Registering User Complete");
          console.log(this.globals);
          //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
        }
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public submitSkipData() {
    //console.log(captchaResponse);
    // submit the search data
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.setupSkipJSONObject(),
    };
    // track the even
    this.http.post(this.searchURlStr, options).subscribe(
      (response) => {
        //console.log(response.json());
        // define properties
        var responseObj = response as any;
        var statusBln = responseObj.statusBln;
        // fork, if there was an error then output the error
        if (!statusBln) {
          this.errorStr = responseObj.errorArr[0];
          // reset the recaptcha
          let myWindow: any = window;
          myWindow.grecaptcha.reset();
        }
        if (statusBln) {
          // create the cookie data object
          let d: Date = new Date();
          d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
          let expires: string = `expires=${d.toUTCString()}`;
          console.log(responseObj);
          document.cookie =
            "containerObj=" +
            responseObj.cookieStr +
            "; " +
            expires +
            "; path=/";
          this.globals.isRegisteredBln = "1";
          this.bsModalRef.hide();
          //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
        }
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public setupInitialJSONObject(obj: { _captchaResponse: any }) {
    // define properties
    var captchaResponse = obj._captchaResponse;
    var modelObj = this.model;
    var siteIDStr = this.globals.siteIDStr;
    var firstNameStr = modelObj.firstName.value;
    var lastNameStr = modelObj.lastName.value;
    var emailAddressStr = modelObj.emailAddress.value;
    var phoneNumberStr = modelObj.phoneNumber.value;
    var ipAddrStr = this.globals.ipAddrStr;
    //set the email address in the global property
    this.globals.emailStr = emailAddressStr;
    // create the JSON Post Object container
    var JSONObj = {
      siteIDStr: siteIDStr,
      firstNameStr: firstNameStr,
      lastNameStr: lastNameStr,
      emailStr: emailAddressStr,
      phoneStr: phoneNumberStr,
      ipAddrStr: ipAddrStr,
      "g-recaptcha-response": captchaResponse,
      initBln: true,
    };
    return JSONObj;
  }

  public setupSkipJSONObject() {
    // define properties
    var modelObj = this.model;
    var siteIDStr = this.globals.siteIDStr;
    var firstNameStr = modelObj.firstName.value;
    var lastNameStr = modelObj.lastName.value;
    var emailAddressStr = modelObj.emailAddress.value;
    var phoneNumberStr = modelObj.phoneNumber.value;
    var ipAddrStr = this.globals.ipAddrStr;
    var uidStr = this.uidStr;
    var captchaResponseStr = this.captchaResponseStr;
    // create the JSON Post Object container
    var JSONObj = {
      siteIDStr: siteIDStr,
      firstNameStr: firstNameStr,
      lastNameStr: lastNameStr,
      emailStr: emailAddressStr,
      phoneStr: phoneNumberStr,
      ipAddrStr: ipAddrStr,
      uidStr: uidStr,
      "g-recaptcha-response": captchaResponseStr,
      registerFullBln: true,
    };
    return JSONObj;
  }

  public setupJSONObject() {
    // define properties
    var modelObj = this.model;
    var siteIDStr = this.globals.siteIDStr;
    var propertyTypeStr = modelObj.propertyType.value;
    var areaStr = modelObj.area.value;
    var priceRangeMinStr = modelObj.priceRangeMin.value;
    var priceRangeMaxStr = modelObj.priceRangeMax.value;
    var bedroomsStr = modelObj.bedrooms.value;
    var bathroomsStr = modelObj.bathrooms.value;
    var lotSizeStr = modelObj.lotSize.value;
    var squareFeetStr = modelObj.squareFeet.value;
    var yearBuiltStr = modelObj.yearBuilt.value;
    var firstNameStr = modelObj.firstName.value;
    var lastNameStr = modelObj.lastName.value;
    var emailAddressStr = modelObj.emailAddress.value;
    var phoneNumberStr = modelObj.phoneNumber.value;
    var ipAddrStr = this.globals.ipAddrStr;
    var uidStr = this.uidStr;
    var captchaResponseStr = this.captchaResponseStr;
    // create the JSON Post Object container
    var JSONObj = {
      siteIDStr: siteIDStr,
      Property_Type: propertyTypeStr,
      searchArea: areaStr,
      searchMin: priceRangeMinStr,
      searchMax: priceRangeMaxStr,
      searchBedrooms: bedroomsStr,
      searchBathrooms: bathroomsStr,
      searchLotSize: lotSizeStr,
      searchSquareFeet: squareFeetStr,
      searchYearBuilt: yearBuiltStr,
      firstNameStr: firstNameStr,
      lastNameStr: lastNameStr,
      emailStr: emailAddressStr,
      phoneStr: phoneNumberStr,
      ipAddrStr: ipAddrStr,
      uidStr: uidStr,
      "g-recaptcha-response": captchaResponseStr,
      registerFullBln: true,
      redirectURLStr: "/listings/dist/",
    };
    return JSONObj;
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }
}
