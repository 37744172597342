<form action="" class="form-properties">
    <a id="moreFilters" (click)="updateFilters()">{{filterHTMLStr}}</a>
    <div class="row-wrap">
        <div class="form-group">
            <label for="searchType">Search Type</label>
            <select class="form-control" id="searchType" name="searchType" (change)="search()"
                    [(ngModel)]="model.searchType.value">
                <option value="Not Specified">Any Type</option>
                <option value="Waterfront Home">Waterfront Home</option>
                <option value="Single Family Home">Single Family Home</option>
                <option value="Condo">Condo</option>
                <option value="Waterfront Building Lot">Waterfront Building Lot</option>
                <option value="Commercial">Commercial</option>
                <option value="Recreational Properties">Recreational Properties</option>
                <option value="Waterfront Properties">Waterfront Properties</option>
            </select>
        </div>
        <div class="form-group">
            <label for="area">Area</label>
            <select class="form-control" id="area" name="area" (change)="search()" [(ngModel)]="model.area.value">
                <option *ngFor="let area of [{name: 'All Of Muskoka', value: 'ALL OF MUSKOKA'}].concat(areas)"
                        [value]="area.value">
                    {{area.name}}
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="showFiltersBln">
        <div class="row-wrap">
            <div class="form-group">
                <label for="priceRangeMin">Price Range</label>
                <select class="form-control" id="priceRangeMin" name="priceRangeMin" (change)="search()"
                        [(ngModel)]="model.priceRangeMin.value">
                    <option value="125000">$125,000</option>
                    <option value="150000">$150,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                    <option value="225000">$225,000</option>
                    <option value="250000">$250,000</option>
                    <option value="275000">$275,000</option>
                    <option value="300000">$300,000</option>
                    <option value="325000">$325,000</option>
                    <option value="350000">$350,000</option>
                    <option value="400000">$400,000</option>
                    <option value="450000">$450,000</option>
                    <option value="500000">$500,000</option>
                    <option value="550000">$550,000</option>
                    <option value="600000">$600,000</option>
                    <option value="650000">$650,000</option>
                    <option value="700000">$700,000</option>
                    <option value="750000">$750,000</option>
                    <option value="800000">$800,000</option>
                    <option value="850000">$850,000</option>
                    <option value="900000">$900,000</option>
                    <option value="1000000">$1,000,000</option>
                    <option value="1250000">$1,250,000</option>
                    <option value="1500000">$1,500,000</option>
                    <option value="1750000">$1,750,000</option>
                    <option value="2000000">$2,000,000</option>
                    <option value="2250000">$2,250,000</option>
                    <option value="2500000">$2,500,000</option>
                    <option value="2750000">$2,750,000</option>
                    <option value="3000000">$3,000,000</option>
                    <option value="3500000">$3,500,000</option>
                    <option value="4000000">$4,000,000</option>
                    <option value="4500000">$4,500,000</option>
                    <option value="5000000">$5,000,000</option>
                    <option value="6000000">$6,000,000</option>
                    <option value="8000000">$8,000,000</option>
                </select>
            </div>
            <div class="form-group">
                <label for="priceRangeMax">to</label>
                <select class="form-control" id="priceRangeMax" name="priceRangeMax" (change)="search()"
                        [(ngModel)]="model.priceRangeMax.value">
                    <option value="100000000">No Max</option>
                    <option value="10000">$10,000</option>
                    <option value="20000">$20,000</option>
                    <option value="30000">$30,000</option>
                    <option value="40000">$40,000</option>
                    <option value="45000">$45,000</option>
                    <option value="50000">$50,000</option>
                    <option value="55000">$55,000</option>
                    <option value="60000">$60,000</option>
                    <option value="70000">$70,000</option>
                    <option value="75000">$75,000</option>
                    <option value="100000">$100,000</option>
                    <option value="125000">$125,000</option>
                    <option value="150000">$150,000</option>
                    <option value="175000">$175,000</option>
                    <option value="200000">$200,000</option>
                    <option value="225000">$225,000</option>
                    <option value="250000">$250,000</option>
                    <option value="275000">$275,000</option>
                    <option value="300000">$300,000</option>
                    <option value="325000">$325,000</option>
                    <option value="350000">$350,000</option>
                    <option value="400000">$400,000</option>
                    <option value="450000">$450,000</option>
                    <option value="500000">$500,000</option>
                    <option value="550000">$550,000</option>
                    <option value="600000">$600,000</option>
                    <option value="650000">$650,000</option>
                    <option value="700000">$700,000</option>
                    <option value="750000">$750,000</option>
                    <option value="800000">$800,000</option>
                    <option value="850000">$850,000</option>
                    <option value="900000">$900,000</option>
                    <option value="1000000">$1,000,000</option>
                    <option value="1250000">$1,250,000</option>
                    <option value="1500000">$1,500,000</option>
                    <option value="1750000">$1,750,000</option>
                    <option value="2000000">$2,000,000</option>
                    <option value="2250000">$2,250,000</option>
                    <option value="2500000">$2,500,000</option>
                    <option value="2750000">$2,750,000</option>
                    <option value="3000000">$3,000,000</option>
                    <option value="3500000">$3,500,000</option>
                    <option value="4000000">$4,000,000</option>
                    <option value="4500000">$4,500,000</option>
                    <option value="5000000">$5,000,000</option>
                    <option value="6000000">$6,000,000</option>
                    <option value="8000000">$8,000,000</option>
                </select>
            </div>
        </div>
        <div class="row-wrap">
            <div class="form-group">
                <label for="bedrooms">Bedroom(s)</label>
                <select class="form-control" id="bedrooms" name="bedrooms" (change)="search()"
                        [(ngModel)]="model.bedrooms.value">
                    <option value="Any">Any</option>
                    <option value="1 or more">1 or more</option>
                    <option value="2 or more">2 or more</option>
                    <option value="3 or more">3 or more</option>
                    <option value="4 or more">4 or more</option>
                </select>
            </div>
            <div class="form-group">
                <label for="bathrooms">Bathroom(s)</label>
                <select class="form-control" id="bathrooms" name="bathrooms" (change)="search()"
                        [(ngModel)]="model.bathrooms.value">
                    <option value="Any">Any</option>
                    <option value="1 or more">1 or more</option>
                    <option value="2 or more">2 or more</option>
                    <option value="3 or more">3 or more</option>
                    <option value="4 or more">4 or more</option>
                </select>
            </div>
        </div>
        <div class="row-wrap">
            <div class="form-group">
                <label for="lotSize">Lot Size</label>
                <select class="form-control" id="lotSize" name="lotSize" (change)="search()"
                        [(ngModel)]="model.lotSize.value">
                    <option value="Any">Any</option>
                    <option value="1/4 or more Acres">1/4 or more Acres</option>
                    <option value="1/2 or more Acres">1/2 or more Acres</option>
                    <option value="1 or more Acres">1 or more Acres</option>
                    <option value="2 or more Acres">2 or more Acres</option>
                    <option value="5 or more Acres">5 or more Acres</option>
                    <option value="10 or more Acres">10 or more Acres</option>
                    <option value="20 or more Acres">20 or more Acres</option>
                    <option value="50 or more Acres">50 or more Acres</option>
                    <option value="100 or more Acres">100 or more Acres</option>
                    <option value="200 or more Acres">200 or more Acres</option>
                    <option value="500 or More Acres">500 or More Acres</option>
                </select>
            </div>
            <div class="form-group">
                <label for="squareFeet">Square Feet</label>
                <select class="form-control" id="squareFeet" name="squareFeet" (change)="search()"
                        [(ngModel)]="model.squareFeet.value">
                    <option value="Any">Any</option>
                    <option value="1,000 or less">1,000 or less</option>
                    <option value="1,000 or more">1,000 or more</option>
                    <option value="1,500 or more">1,500 or more</option>
                    <option value="2,000 or more">2,000 or more</option>
                    <option value="2,500 or more">2,500 or more</option>
                    <option value="3,000 or more">3,000 or more</option>
                    <option value="3,500 or more">3,500 or more</option>
                    <option value="4,000 or more">4,000 or more</option>
                </select>
            </div>
            <div class="form-group">
                <label for="yearBuilt">Year Built</label>
                <select class="form-control" id="yearBuilt" name="yearBuilt" (change)="search()"
                        [(ngModel)]="model.yearBuilt.value">
                    <option value="All">All</option>
                    <option value="2010">2010 or Newer</option>
                    <option value="2009">2009 or Newer</option>
                    <option value="2008">2008 or Newer</option>
                    <option value="2007">2007 or Newer</option>
                    <option value="2006">2006 or Newer</option>
                    <option value="2005">2005 or Newer</option>
                    <option value="2004">2004 or Newer</option>
                    <option value="2003">2003 or Newer</option>
                    <option value="2002">2002 or Newer</option>
                    <option value="2001">2001 or Newer</option>
                    <option value="2000">2000 or Newer</option>
                    <option value="1999">1999 or Newer</option>
                    <option value="1998">1998 or Newer</option>
                    <option value="1997">1997 or Newer</option>
                    <option value="1996">1996 or Newer</option>
                    <option value="1995">1995 or Newer</option>
                    <option value="1994">1994 or Newer</option>
                    <option value="1993">1993 or Newer</option>
                    <option value="1992">1992 or Newer</option>
                    <option value="1991">1991 or Newer</option>
                    <option value="1990">1990 or Newer</option>
                    <option value="1989">1989 or Newer</option>
                    <option value="1988">1988 or Newer</option>
                    <option value="1987">1987 or Newer</option>
                    <option value="1986">1986 or Newer</option>
                    <option value="1985">1985 or Newer</option>
                    <option value="1984">1984 or Newer</option>
                    <option value="1983">1983 or Newer</option>
                    <option value="1982">1982 or Newer</option>
                    <option value="1981">1981 or Newer</option>
                    <option value="1980">1980 or Newer</option>
                    <option value="1979">1979 or Newer</option>
                    <option value="1978">1978 or Newer</option>
                    <option value="1977">1977 or Newer</option>
                    <option value="1976">1976 or Newer</option>
                    <option value="1975">1975 or Newer</option>
                    <option value="1974">1974 or Newer</option>
                    <option value="1973">1973 or Newer</option>
                    <option value="1972">1972 or Newer</option>
                    <option value="1971">1971 or Newer</option>
                    <option value="1970">1970 or Newer</option>
                    <option value="1969">1969 or Newer</option>
                    <option value="1968">1968 or Newer</option>
                    <option value="1967">1967 or Newer</option>
                    <option value="1966">1966 or Newer</option>
                    <option value="1965">1965 or Newer</option>
                    <option value="1964">1964 or Newer</option>
                    <option value="1963">1963 or Newer</option>
                    <option value="1962">1962 or Newer</option>
                    <option value="1961">1961 or Newer</option>
                    <option value="1960">1960 or Newer</option>
                    <option value="1959">1959 or Newer</option>
                    <option value="1958">1958 or Newer</option>
                    <option value="1957">1957 or Newer</option>
                    <option value="1956">1956 or Newer</option>
                    <option value="1955">1955 or Newer</option>
                    <option value="1954">1954 or Newer</option>
                    <option value="1953">1953 or Newer</option>
                    <option value="1952">1952 or Newer</option>
                    <option value="1951">1951 or Newer</option>
                    <option value="1950">1950 or Newer</option>
                    <option value="1949">1949 or Newer</option>
                    <option value="1948">1948 or Newer</option>
                    <option value="1947">1947 or Newer</option>
                    <option value="1946">1946 or Newer</option>
                    <option value="1945">1945 or Newer</option>
                    <option value="1944">1944 or Newer</option>
                    <option value="1943">1943 or Newer</option>
                    <option value="1942">1942 or Newer</option>
                    <option value="1941">1941 or Newer</option>
                    <option value="1940">1940 or Newer</option>
                    <option value="1939">1939 or Newer</option>
                    <option value="1938">1938 or Newer</option>
                    <option value="1937">1937 or Newer</option>
                    <option value="1936">1936 or Newer</option>
                    <option value="1935">1935 or Newer</option>
                    <option value="1934">1934 or Newer</option>
                    <option value="1933">1933 or Newer</option>
                    <option value="1932">1932 or Newer</option>
                    <option value="1931">1931 or Newer</option>
                    <option value="1930">1930 or Newer</option>
                    <option value="1929">1929 or Newer</option>
                    <option value="1928">1928 or Newer</option>
                    <option value="1927">1927 or Newer</option>
                    <option value="1926">1926 or Newer</option>
                    <option value="1925">1925 or Newer</option>
                    <option value="1924">1924 or Newer</option>
                    <option value="1923">1923 or Newer</option>
                    <option value="1922">1922 or Newer</option>
                    <option value="1921">1921 or Newer</option>
                    <option value="1920">1920 or Newer</option>
                    <option value="1919">1919 or Newer</option>
                    <option value="1918">1918 or Newer</option>
                    <option value="1917">1917 or Newer</option>
                    <option value="1916">1916 or Newer</option>
                    <option value="1915">1915 or Newer</option>
                    <option value="1914">1914 or Newer</option>
                    <option value="1913">1913 or Newer</option>
                    <option value="1912">1912 or Newer</option>
                    <option value="1911">1911 or Newer</option>
                    <option value="1910">1910 or Newer</option>
                    <option value="1909">1909 or Newer</option>
                    <option value="1908">1908 or Newer</option>
                    <option value="1907">1907 or Newer</option>
                    <option value="1906">1906 or Newer</option>
                    <option value="1905">1905 or Newer</option>
                    <option value="1904">1904 or Newer</option>
                    <option value="1903">1903 or Newer</option>
                    <option value="1902">1902 or Newer</option>
                    <option value="1901">1901 or Newer</option>
                    <option value="1900">1900 or Newer</option>
                    <option value="1899">1899 or Newer</option>
                    <option value="1898">1898 or Newer</option>
                    <option value="1897">1897 or Newer</option>
                    <option value="1896">1896 or Newer</option>
                    <option value="1895">1895 or Newer</option>
                    <option value="1894">1894 or Newer</option>
                    <option value="1893">1893 or Newer</option>
                    <option value="1892">1892 or Newer</option>
                    <option value="1891">1891 or Newer</option>
                    <option value="1890">1890 or Newer</option>
                    <option value="1889">1889 or Newer</option>
                    <option value="1888">1888 or Newer</option>
                    <option value="1887">1887 or Newer</option>
                    <option value="1886">1886 or Newer</option>
                    <option value="1885">1885 or Newer</option>
                    <option value="1884">1884 or Newer</option>
                    <option value="1883">1883 or Newer</option>
                    <option value="1882">1882 or Newer</option>
                    <option value="1881">1881 or Newer</option>
                    <option value="1880">1880 or Newer</option>
                    <option value="1879">1879 or Newer</option>
                    <option value="1878">1878 or Newer</option>
                    <option value="1877">1877 or Newer</option>
                    <option value="1876">1876 or Newer</option>
                    <option value="1875">1875 or Newer</option>
                    <option value="1874">1874 or Newer</option>
                    <option value="1873">1873 or Newer</option>
                    <option value="1872">1872 or Newer</option>
                    <option value="1871">1871 or Newer</option>
                    <option value="1870">1870 or Newer</option>
                    <option value="1869">1869 or Newer</option>
                    <option value="1868">1868 or Newer</option>
                    <option value="1867">1867 or Newer</option>
                    <option value="1866">1866 or Newer</option>
                    <option value="1865">1865 or Newer</option>
                    <option value="1864">1864 or Newer</option>
                    <option value="1863">1863 or Newer</option>
                    <option value="1862">1862 or Newer</option>
                    <option value="1861">1861 or Newer</option>
                    <option value="1860">1860 or Newer</option>
                    <option value="1859">1859 or Newer</option>
                    <option value="1858">1858 or Newer</option>
                    <option value="1857">1857 or Newer</option>
                    <option value="1856">1856 or Newer</option>
                    <option value="1855">1855 or Newer</option>
                    <option value="1854">1854 or Newer</option>
                    <option value="1853">1853 or Newer</option>
                    <option value="1852">1852 or Newer</option>
                    <option value="1851">1851 or Newer</option>
                    <option value="1850">1850 or Newer</option>
                    <option value="1849">1849 or Newer</option>
                    <option value="1848">1848 or Newer</option>
                    <option value="1847">1847 or Newer</option>
                    <option value="1846">1846 or Newer</option>
                    <option value="1845">1845 or Newer</option>
                    <option value="1844">1844 or Newer</option>
                    <option value="1843">1843 or Newer</option>
                    <option value="1842">1842 or Newer</option>
                    <option value="1841">1841 or Newer</option>
                    <option value="1840">1840 or Newer</option>
                    <option value="1839">1839 or Newer</option>
                    <option value="1838">1838 or Newer</option>
                    <option value="1837">1837 or Newer</option>
                    <option value="1836">1836 or Newer</option>
                    <option value="1835">1835 or Newer</option>
                    <option value="1834">1834 or Newer</option>
                    <option value="1833">1833 or Newer</option>
                    <option value="1832">1832 or Newer</option>
                    <option value="1831">1831 or Newer</option>
                    <option value="1830">1830 or Newer</option>
                    <option value="1829">1829 or Newer</option>
                    <option value="1828">1828 or Newer</option>
                    <option value="1827">1827 or Newer</option>
                    <option value="1826">1826 or Newer</option>
                    <option value="1825">1825 or Newer</option>
                    <option value="1824">1824 or Newer</option>
                    <option value="1823">1823 or Newer</option>
                    <option value="1822">1822 or Newer</option>
                    <option value="1821">1821 or Newer</option>
                    <option value="1820">1820 or Newer</option>
                    <option value="1819">1819 or Newer</option>
                    <option value="1818">1818 or Newer</option>
                    <option value="1817">1817 or Newer</option>
                    <option value="1816">1816 or Newer</option>
                    <option value="1815">1815 or Newer</option>
                    <option value="1814">1814 or Newer</option>
                    <option value="1813">1813 or Newer</option>
                    <option value="1812">1812 or Newer</option>
                    <option value="1811">1811 or Newer</option>
                    <option value="1810">1810 or Newer</option>
                </select>
            </div>
        </div>
    </div>
</form>