import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { Globals } from "./globals";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "my-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./dashboard.component.css"],
  standalone: true,
})
export class FooterComponent implements OnInit {
  public isCollapsed: boolean = true;
  bsModalRef: BsModalRef | undefined;

  constructor(
    public router: Router,
    private http: HttpClient,
    private globals: Globals,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    //console.log('hello');
  }

  public redirect(idStr: string) {
    this.router.navigate(["./" + idStr]);
  }

  openModal(template: TemplateRef<any>) {
    console.log("here");
    this.bsModalRef = this.modalService.show(template);
  }

  closeModal() {
    if (this.bsModalRef == undefined) return;
    this.modalService.hide();
  }
}
