import { Injectable } from '@angular/core';

function getWindow():any {
  return window;
}

@Injectable()
export class WindowReferenceService {
  get nativeWindow() {
    return getWindow();
  }
}
