import { AfterViewInit, Component, ElementRef, OnInit } from "@angular/core";

import { filter, pairwise } from "rxjs/operators";

import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

import { Globals } from "./globals";

declare let ga: Function;

@Component({
  selector: "my-root",
  template: `
    <router-outlet
      infiniteScroll
      [immediateCheck]="true"
      [fromRoot]="true"
      [infiniteScrollDistance]="3"
      [infiniteScrollUpDistance]="4"
      [infiniteScrollThrottle]="0"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
      [scrollWindow]="true"
    ></router-outlet>
  `,
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements AfterViewInit, OnInit {
  title = "Tour of Heroes";
  private hostnameStr: string = "";
  private emailStr: string = "";
  private propertyTypeStr: string = "";
  private cityStr: string = "";
  private userIDStr: string = "";
  private savedSearchIDStr = "";
  private ipAddrStr = "";
  private sidStr = "";
  private statusStr = "";
  private isRegisteredBln = "";

  private docElem = document.documentElement;
  private didScroll = false;
  private changeHeaderOn = 1;
  private initBln: boolean = true;

  private _routeScrollPositions: { [url: string]: number } = {};
  private _subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private location: Location,
    elementRef: ElementRef,
    public globals: Globals,
  ) {
    // define properties
    this.hostnameStr = elementRef.nativeElement.getAttribute("hostnameStr");
    this.emailStr = elementRef.nativeElement.getAttribute("emailStr");
    this.propertyTypeStr =
      elementRef.nativeElement.getAttribute("propertyTypeStr");
    this.cityStr = elementRef.nativeElement.getAttribute("cityStr");
    this.userIDStr = elementRef.nativeElement.getAttribute("userIDStr");
    this.savedSearchIDStr =
      elementRef.nativeElement.getAttribute("savedSearchIDStr");
    this.ipAddrStr = elementRef.nativeElement.getAttribute("ipAddrStr");
    this.sidStr = elementRef.nativeElement.getAttribute("sidStr");
    this.statusStr = elementRef.nativeElement.getAttribute("statusStr");
    this.isRegisteredBln =
      elementRef.nativeElement.getAttribute("isRegisteredBln");
    // set the passed parameters into the globals for external reference
    if (this.hostnameStr != "") globals.hostnameStr = this.hostnameStr;
    if (this.emailStr != "") globals.emailStr = this.emailStr;
    if (this.propertyTypeStr != "")
      globals.propertyTypeStr = this.propertyTypeStr;
    if (this.cityStr != "") globals.districtList = this.cityStr;
    if (this.userIDStr != "") globals.userIDStr = this.userIDStr;
    if (this.savedSearchIDStr != "")
      globals.savedSearchIDStr = this.savedSearchIDStr;
    if (this.ipAddrStr != "") globals.ipAddrStr = this.ipAddrStr;
    if (this.sidStr != "") globals.sidStr = this.sidStr;
    if (this.statusStr != "") globals.statusStr = this.statusStr;
    if (this.isRegisteredBln != "" && this.emailStr != "")
      globals.isRegisteredBln = this.isRegisteredBln;
    if (router.url == "/search/toronto") globals.districtList = "TORONTO";
    else globals.districtList = "ALL OF MUSKOKA";
    // switch, based on the user defined domain then load the correct listings
    switch (this.hostnameStr) {
      case "oakvillesluxuryrealestate.com":
        globals.districtList = "Oakville";
        globals.siteIDStr = 3069;
        break;
      case "mississaugasluxuryrealestate.com":
        globals.districtList = "Mississauga";
        globals.siteIDStr = 3069;
        break;
      case "southcaymanmarshall.com":
        globals.districtList = "Mississauga";
        globals.siteIDStr = 3073;
        break;
      case "portcreditluxuryrealestate.com":
        globals.districtList = "Port Credit";
        globals.enableExclusiveListingsBln = false;
        globals.siteIDStr = 3072;
        break;
      case "mineolaluxuryrealestate.com":
        globals.districtList = "Mineola";
        globals.enableExclusiveListingsBln = false;
        globals.siteIDStr = 3070;
        break;
      case "lorneparkluxuryrealestate.ca":
        globals.districtList = "Lorne Park";
        globals.enableExclusiveListingsBln = false;
        globals.siteIDStr = 3071;
        break;
      case "burlingtonluxuryrealestate.com":
        globals.districtList = "Burlington";
        globals.enableExclusiveListingsBln = false;
        globals.siteIDStr = 3074;
        break;
    }
    // fork, determine if the user has used the lead capture domains, if so then disable the links
    if (
      this.hostnameStr == "oakvillesluxuryrealestate.com" ||
      this.hostnameStr == "mississaugasluxuryrealestate.com" ||
      this.hostnameStr == "burlingtonluxuryrealestate.com" ||
      this.hostnameStr == "southcaymanmarshall.com" ||
      this.hostnameStr == "portcreditluxuryrealestate.com" ||
      this.hostnameStr == "mineolaluxuryrealestate.com" ||
      this.hostnameStr == "lorneparkluxuryrealestate.ca"
    )
      globals.linksEnabledBln = false;
    else globals.linksEnabledBln = true;
    console.log(globals);
    // create the listener for page changes and send the google analytics data
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log("previous URL: " + event.url);
        // fork, if this is the homepage then make sure to log the url
        if (event.url == "/") globals.previousURLStr = "/";
        //ga('set', 'page', event.urlAfterRedirects);
        //ga('send', 'pageview');
        (<any>window).dataLayer.push({
          userIDStr: globals.userIDStr,
          sidStr: globals.sidStr,
          event: "pageview",
          page: {
            path: event.urlAfterRedirects,
          },
        });
      }
    });
  }

  ngOnInit(): void {
    console.log("ngOnInit");
    // define properties
    this.router.events.subscribe((event) => {
      //console.log('init: ' + event);
      var header = document.querySelector(".navbar-default");
      var changeHeaderOn = this.changeHeaderOn;
      var sy = this.scrollY();
      // example: NavigationStart, RoutesRecognized, NavigationEnd
      //console.log(event);
      if (sy >= changeHeaderOn && header != null) {
        //console.log(sy);
        header.classList.add("navbar-shrink");
      }
      const element = document.querySelector(".intro-heading");
      //if (element) element.scrollIntoView(element);

      //window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit(): void {
    //console.log('ngAfterViewInit');
    var header = document.querySelector(".navbar-default");
    var changeHeaderOn = this.changeHeaderOn;
    var sy = this.scrollY();
    if (sy >= changeHeaderOn && header != null) {
      header.classList.add("navbar-shrink");
    }

    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise(),
      )
      .subscribe((events: RoutesRecognized[]) => {
        // url path without parameters
        let urlPathStr = events[0].urlAfterRedirects.split(";", 1)[0];
        setTimeout(() => this.getScrollTop("resultsList", urlPathStr), 0);
      });
    /*

    // restore scroll position on back or forward for the results view
    this.location.subscribe(event => {
        // back or forward, wait for router navigation end
        let routerNavigationEnd = this.router.events.first(event => event instanceof NavigationEnd)
            .subscribe((navigationEndEvent: NavigationEnd) => {
                // url path without parameters
                let urlPathStr= (navigationEndEvent.urlAfterRedirects || navigationEndEvent.url).split(';',1)[0];
                //setTimeout(this.setScrollTo('resultsList', urlPathStr), 0);
            });
    });*/
  }

  getScrollTop(nodeIdStr: string, urlPathStr: string) {
    let node = document.getElementById(nodeIdStr);
    if (node) {
      let scrollTopNum = node.scrollTop;
      if (scrollTopNum == 0) {
        console.log("mobile version");
        // set the mobile version boolean indicator
        this.globals.mobileBln = true;
        scrollTopNum = this.scrollY();
        console.log(document.documentElement.scrollTop);
      } else {
        // make sure to set the mobile version boolean indicator to false
        this.globals.mobileBln = false;
      }
      this._routeScrollPositions[urlPathStr] = scrollTopNum;
      this.globals.scrollTopNum = scrollTopNum;
      console.log("offset: " + scrollTopNum);
      console.log(window);
    }
  }

  onScrollDown() {
    //console.log("scrolled down!!");
    var header = document.querySelector(".navbar-default");
    if (header == null) return;
    var changeHeaderOn = this.changeHeaderOn;
    var sy = this.scrollY();
    if (sy >= changeHeaderOn) {
      header.classList.add("navbar-shrink");
    }
  }

  onScrollUp() {
    //console.log("scrolling up");
    var header = document.querySelector(".navbar-default");
    if (header == null) return;
    var changeHeaderOn = this.changeHeaderOn;
    var sy = this.scrollY();
    console.log("changing header:" + sy);
    if (sy <= changeHeaderOn) {
      header.classList.remove("navbar-shrink");
    }
  }

  scrollY() {
    return window.pageYOffset || this.docElem.scrollTop;
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
