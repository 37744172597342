import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Globals } from "./globals";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, CollapseModule],
  selector: "my-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class NavigationComponent implements OnInit, OnDestroy {
  public isCollapsed: boolean = true;
  public isLinksEnabled: boolean = false;
  destroyed = new Subject<void>();
  currentScreenSize: string | undefined;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.Handset, "Handset"],
    [Breakpoints.Tablet, "Tablet"],
    [Breakpoints.Web, "Web"],
  ]);

  constructor(
    public router: Router,
    private http: HttpClient,
    private responsive: BreakpointObserver,
    public globals: Globals,
  ) {
    inject(BreakpointObserver)
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? "Unknown";
          }
        }
      });
    if (globals.linksEnabledBln) this.isLinksEnabled = true;
  }

  ngOnInit(): void {
    //console.log('hello');
  }

  redirect(idStr: string) {
    this.router.navigate(["./" + idStr]);
  }

  public collapsed(event: any): void {
    //console.log('collapsed');
    //console.log(this.isCollapsed);
    //console.log(event);
  }

  public expanded(event: any): void {
    //console.log('expanded');
    //console.log(this.isCollapsed);
    //console.log(event);
  }

  public collapseMenu() {
    //console.log('collapse menu');
    this.isCollapsed = true;
    this.globals.savedSearchIDStr = "";
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
