import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { Listing } from "./listing";
import { ListingDetail } from "./listing-detail";
import { Globals } from "./globals";

@Injectable()
export class TorontoListingsService {
  private listingsUrlStr = "/includes/rets/toronto/REP_RETSSearch.php"; // URL to web api
  private listingDetailsUrlStr = "/includes/rets/REP_MLSListing.Toronto.php"; // URL to web api
  private listingDetailsUrlCMStr = "/includes/rets/REP_MLSListing.php"; // URL to web api

  constructor(
    private http: HttpClient,
    private globals: Globals,
  ) {}

  getListings(): any {
    console.log(this.globals);
    //console.log(this.globals);
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.getFilterPostObj(),
    };
    return this.http.post<Listing[]>(this.listingsUrlStr, options).subscribe(
      (response: any) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr;
        var countNum = dataObj.countNum;
        this.globals.countNum = countNum;
        return dataArr as Listing[];
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  getListing(MLSNum: string | null, sourceStr: string | null): any {
    // define properties
    var urlStr =
      sourceStr == "TREB"
        ? this.listingDetailsUrlStr
        : this.listingDetailsUrlCMStr;
    let paramsObj = this.getDetailsPostObj({ _MLSNum: MLSNum });
    let params = new HttpParams({
      fromObject: paramsObj,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    return this.http.post<ListingDetail>(
      urlStr,
      params.toString(),
      httpOptions,
    );
  }

  loadMore() {
    // set the global limit num
    this.globals.startNum += 10;
    this.globals.endNum += 10;
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.getFilterPostObj(),
    };
    return this.http.post(this.listingsUrlStr, options);
  }

  filterListings(searchObj: Object): any {
    //console.log(this.globals);
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.getFilterPostObj(),
    };
    return this.http.post(this.listingsUrlStr, options).subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr;
        var countNum = dataObj.countNum;
        this.globals.countNum = countNum;
        return dataArr as Listing[];
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  private getPostObj() {
    //console.log('here');
    // define properties
    var limitNum = this.globals.limitNum;
    var startNum = this.globals.startNum;
    var endNum = this.globals.endNum;
    var indexNum = endNum + 1;
    var idStr = this.globals.idStr;
    var statusStr = this.globals.statusStr;
    var orderStr = "DESC";
    var stateStr = this.globals.stateStr;
    // generate the JSON Object property for posting to the server
    var JSONObj = {
      limitNum: limitNum,
      startNum: startNum,
      endNum: endNum,
      indexNum: indexNum,
      idStr: idStr,
      orderStr: orderStr,
      stateStr: stateStr,
    };
    // return the json object to send to as post data
    return JSONObj;
  }

  private getFilterPostObj() {
    //console.log('there');
    // define properties
    var limitNum = this.globals.limitNum;
    var startNum = this.globals.startNum;
    var endNum = this.globals.endNum;
    var indexNum = endNum + 1;
    var idStr = this.globals.idStr;
    var statusStr = this.globals.statusStr;
    var orderStr = "DESC";
    var stateStr = this.globals.stateStr;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var districtList = "Toronto";
    var searchMin = this.globals.searchMin;
    var searchMax = this.globals.searchMax;
    var bedroomsNum = this.globals.bedroomsNum;
    var bathroomsNum = this.globals.bathroomsNum;
    var squareFeetStr = this.globals.squareFeetStr;
    var savedSearchIDStr = this.globals.savedSearchIDStr;
    var userIDStr = this.globals.userIDStr;
    // generate the JSON Object property for posting to the server
    var JSONObj = {
      limitNum: limitNum,
      startNum: startNum,
      endNum: endNum,
      indexNum: indexNum,
      idStr: idStr,
      orderStr: orderStr,
      statusStr: statusStr,
      stateStr: stateStr,
      propertyTypeStr: "",
      districtList: "",
      searchMin: "",
      searchMax: "",
      bedroomsNum: "",
      bathroomsNum: "",
      squareFeetStr: "",
      savedSearchIDStr: "",
      userIDStr: "",
    };
    if (propertyTypeStr != "Not Specified")
      JSONObj.propertyTypeStr = propertyTypeStr;
    else delete (JSONObj as any)["propertyTypeStr"];
    if (districtList != "All Of Muskoka") JSONObj.districtList = districtList;
    else delete (JSONObj as any)["districtList"];
    if (searchMin != "125000") JSONObj.searchMin = searchMin;
    else delete (JSONObj as any)["searchMin"];
    if (searchMax != "100000000") JSONObj.searchMax = searchMax;
    else delete (JSONObj as any)["searchMax"];
    if (bedroomsNum != "Any") JSONObj.bedroomsNum = bedroomsNum;
    else delete (JSONObj as any)["bedroomsNum"];
    if (bathroomsNum != "Any") JSONObj.bathroomsNum = bathroomsNum;
    else delete (JSONObj as any)["bathroomsNum"];
    if (squareFeetStr != "Any") JSONObj.squareFeetStr = squareFeetStr;
    else delete (JSONObj as any)["squareFeetStr"];
    if (savedSearchIDStr != "") JSONObj.savedSearchIDStr = savedSearchIDStr;
    else delete (JSONObj as any)["savedSearchIDStr"];
    if (userIDStr != "") JSONObj.userIDStr = userIDStr;
    else delete (JSONObj as any)["userIDStr"];
    //if (yearBuilt!='All') JSONObj.yearBuilt=yearBuilt;
    //console.log(JSONObj);
    // return the json object to send to as post data
    return JSONObj;
  }

  private getDetailsPostObj(obj: { _MLSNum: any }) {
    // define properties
    var MLSNum = obj._MLSNum;
    var propertyTypeStr = "res";
    return { MLSNum: MLSNum, propertyTypeStr: propertyTypeStr };
  }
}
