import { Component, inject, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ActivatedRoute,
  ParamMap,
  Router,
  RouterOutlet,
} from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { switchMap, takeUntil } from "rxjs/operators";
import { SEOService } from "./seo.service";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { Globals } from "./globals";

import { FooterComponent } from "./footer.component";
import { NavigationComponent } from "./navigation.component";
import { ListingDetail } from "./listing-detail";
import { PeelHaltonListingsService } from "./peelhalton-listings.service";
import { TorontoListingsService } from "./toronto-listings.service";
import { NavigationDetailsFullComponent } from "./navigation-details-full.component";
import { ContactFormComponent } from "./contact-form.component";
import { Subject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { Listing } from "./listing";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ScrollToModule,
    FooterComponent,
    NavigationComponent,
    NavigationDetailsFullComponent,
    ContactFormComponent,
    InfiniteScrollDirective,
    RouterOutlet,
  ],
  selector: "peelhalton-listing-details-full",
  templateUrl: "./peelhalton-listing-details-full.component.html",
  styleUrls: ["./listing-details-full.component.css"],
})
export class PeelHaltonListingDetailsFullComponent implements OnInit {
  private trackingURLStr = "/classes/REP_RETSTracking.php"; // URL to web api
  selectedListing: ListingDetail | undefined;
  loadedBln: boolean = false;
  destroyed = new Subject<void>();
  currentScreenSize: string | undefined;
  private changeHeaderOn = 200;
  private docElem = document.documentElement;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, "XSmall"],
    [Breakpoints.Small, "Small"],
    [Breakpoints.Medium, "Medium"],
    [Breakpoints.Large, "Large"],
    [Breakpoints.XLarge, "XLarge"],
  ]);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private listingsService: PeelHaltonListingsService,
    private torontoListingsService: TorontoListingsService,
    private SEOService: SEOService,
    public globals: Globals,
    private _sanitizer: DomSanitizer,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? "Unknown";
            console.log("screen size: " + this.currentScreenSize);
          }
        }
      });
    // load the page details
    this.loadDetails();
  }

  ngOnInit(): void {}

  loadDetails(): void {
    //console.log('detail indirect');
    let etobicokeArr = [
      "Etobicoke",
      "Long Branch",
      "Alderwood",
      "Mimico",
      "New Toronto",
      "Humber Bay Shores",
      "Stonegate-Queensway",
    ];
    //console.log('detail indirect');
    console.log("globals------------------------------");
    console.log(this.globals);
    let etobicokeBln = etobicokeArr.includes(this.globals.districtList);
    // fork, if this is a non etobicoke search then use the peel halton db to search for the listing
    let listingsServiceCls = !etobicokeBln
      ? this.listingsService
      : this.torontoListingsService;
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          listingsServiceCls.getListing(
            params.get("MLSNum"),
            params.get("sourceStr"),
          ),
        ),
      )
      .subscribe((listingsDetails) => {
        listingsDetails = this.fixImages(listingsDetails as ListingDetail);
        this.selectedListing = listingsDetails as ListingDetail;
        console.log(listingsDetails);
        this.loadedBln = true;
        // track the event
        this.trackEvent({ _eventStr: "view-listing" });
        // set the seo data
        this.setSEOTags();
      });
  }

  private fixImages(listingDetailsCls: ListingDetail) {
    // define properties
    var imageArr = listingDetailsCls.imageArr;
    // iterate through the image array to fix the image URL's to the new serverless image resize solution
    for (var i = 0; i < imageArr.length; i++) {
      // define properties
      var urlStr = imageArr[i];
      // change the url to the new one
      urlStr = urlStr.replace(
        "http://dhauv7lm2gh1r.cloudfront.net/",
        "https://d3mtjy7k2lo3ql.cloudfront.net/fit-in/1900x1200/",
      );
      // update the array item
      listingDetailsCls.imageArr[i] = urlStr;
    }
    // return the listing details class
    return listingDetailsCls;
  }

  private setSEOTags() {
    // define properties
    var listingDetailsCls = this.selectedListing;
    if (listingDetailsCls == undefined) return;
    var addressStr = listingDetailsCls.addressStr;
    var addressHyphenStr = listingDetailsCls.addressHyphenStr;
    var cityStr = listingDetailsCls.cityStr;
    var cityHypenStr = listingDetailsCls.cityHyphenStr;
    var stateStr = listingDetailsCls.stateStr;
    var postalCodeStr = listingDetailsCls.postalCodeStr;
    var MLSNum = listingDetailsCls.MLSNum;
    var titleStr =
      addressStr +
      ", " +
      cityStr +
      ", " +
      stateStr +
      " " +
      postalCodeStr +
      " (MLS #" +
      MLSNum +
      ") :: Cayman Marshall International Realty Inc., Brokerage";
    var descriptionStr =
      "Residential property for sale in " +
      cityStr +
      ", " +
      stateStr +
      " (MLS #" +
      MLSNum +
      "). Learn more from Cayman Marshall.";
    var keywordsStr =
      "Real Estate, Houses, " +
      addressStr +
      ", " +
      cityStr +
      ", " +
      stateStr +
      ", Residential, Full Service, $/Sf/Month, Yes, No";
    // set the SEO data
    this.SEOService.setMetaTags({
      _titleStr: titleStr,
      _descriptionStr: descriptionStr,
      _urlStr:
        "https://www." +
        this.globals.hostnameStr +
        "/search/fulldetails/" +
        addressHyphenStr +
        "/" +
        cityHypenStr +
        "/" +
        MLSNum,
      _keywordsStr: keywordsStr,
    });
  }

  getBackground(image: string) {
    // deine properties
    var urlStr = image.replace(
      "http://dhauv7lm2gh1r.cloudfront.net/",
      "https://d3mtjy7k2lo3ql.cloudfront.net/fit-in/1900x1200/",
    );
    return this._sanitizer.bypassSecurityTrustStyle(`url(${urlStr})`);
  }

  public onScrollDown() {
    //console.log("scrolled down!!");
  }

  public onScrollUp() {
    //console.log("scrolled up!!");
    var header = document.querySelector(".navbar-default");
    if (header == null) return;
    var changeHeaderOn = this.changeHeaderOn;
    var sy = this.scrollY();
    if (sy <= changeHeaderOn) {
      header.classList.remove("navbar-shrink");
    }
  }

  scrollY() {
    return window.pageYOffset || this.docElem.scrollTop;
  }

  public trackEvent(obj: { _eventStr: string }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.generateEventTrackingData({ obj: obj }),
    };
    let paramsObj = this.generateEventTrackingData({ obj: obj });
    let params = new HttpParams({
      fromObject: paramsObj,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    // track the event
    return this.http
      .post<Listing[]>(this.trackingURLStr, params.toString(), httpOptions)
      .subscribe(
        (response) => {
          //console.log(response.json());
        },
        (error) => {
          this.handleError(error); // Centralized error handling
        },
      );
  }

  private generateEventTrackingData({ obj }: { obj: any }): any {
    // define properties
    var emailStr = this.globals.emailStr;
    var userIDStr = this.globals.userIDStr;
    var ipAddrStr = this.globals.ipAddrStr;
    var sidStr = this.globals.sidStr;
    var idStr = this.globals.idStr;
    var eventStr = obj._eventStr;
    var eventFullStr = this.generateEventFullString();
    // define properties
    var JSONObj = {
      idStr: idStr,
      emailStr: "",
      userIDStr: "",
      ipAddrStr: ipAddrStr,
      sessionIDStr: "",
      eventStr: eventStr,
      eventFullStr: eventFullStr,
      viewStr: "list-view",
    };
    if (emailStr != "") JSONObj.emailStr = emailStr;
    else delete (JSONObj as any)["emailStr"];
    if (userIDStr != "") JSONObj.userIDStr = userIDStr;
    else delete (JSONObj as any)["userIDStr"];
    if (sidStr != "") JSONObj.sessionIDStr = sidStr;
    else delete (JSONObj as any)["sessionIDStr"];
    return JSONObj;
  }

  private generateEventFullString() {
    // define properties
    if (this.selectedListing == undefined) return;
    var MLSNum = this.selectedListing.MLSNum;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var eventFullObj = {
      MLSNum: MLSNum,
      propertyTypeStr: propertyTypeStr,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
