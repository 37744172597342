import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { Listing } from "./listing";
import { ListingDetail } from "./listing-detail";
import { TorontoListingsService } from "./toronto-listings.service";
import { SEOService } from "./seo.service";
import { ModalContentComponent } from "./modal-content.component";
import { TorontoListingsSearchComponent } from "./toronto-listings-search.component";
import { NavigationComponent } from "./navigation.component";

import { Globals } from "./globals";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { NgbdCarouselConfig } from "./carousel-config";
import { Subject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";

declare let ga: Function;

@Component({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    TorontoListingsSearchComponent,
    NavigationComponent,
    RouterModule,
    NgbdCarouselConfig,
  ],
  standalone: true,
  selector: "my-toronto-dashboard",
  templateUrl: "./toronto-dashboard.component.html",
  styleUrls: ["./toronto-dashboard.component.css"],
})
export class TorontoDashboardComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  private trackingURLStr = "/classes/REP_RETSTracking.php"; // URL to web api
  listings: Listing[] = [];
  @Input() selectedListing: ListingDetail | undefined;
  @Input() mynumber: any;
  countNum: number = 0;
  public isSelectedBln: boolean = false;
  public listingsViewedNum = 0;
  public isRegisteredBln = false;
  bsModalRef: BsModalRef | undefined;
  public subscriptions: any[] = [];
  public messages: string[] = [];
  public step2Bln = false;
  destroyed = new Subject<void>();
  currentScreenSize: string | undefined;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.Handset, "Handset"],
    [Breakpoints.Tablet, "Tablet"],
    [Breakpoints.Web, "Web"],
  ]);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private torontoListingsService: TorontoListingsService,
    private SEOService: SEOService,
    public globals: Globals,
    private elementRef: ElementRef,
    private modalService: BsModalService,
  ) {
    inject(BreakpointObserver)
      .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          console.log("screen size" + query);
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? "Unknown";
          }
        }
      });
    //console.log('hello');
    // set the SEO details
    SEOService.setMetaTags({
      _titleStr:
        "Muskoka Luxury Real Estate - Cayman Marshall International Realty Inc. - Search",
      _descriptionStr:
        "Muskoka Luxury Real Estate - Lake Rosseau Muskoka Joseph and Lake Of Bays",
      _urlStr: "https://m.oakvillehomerealestate.net" + router.url,
      _keywordsStr: "",
    });
  }

  ngOnInit(): void {
    console.log("init Toronto Dashboard");
    console.log("Path: " + this.router.url);
    console.log(this.globals.statusStr);
    // define properties
    var routerURLStr = this.router.url;
    //console.log(this.listings);
    //this.resetPaging();
    if (routerURLStr == "/sold" || routerURLStr == "/search/sold")
      this.globals.statusStr = "Sold";
    else if (routerURLStr == "/rentals" || routerURLStr == "/search/rentals")
      this.globals.statusStr = "Rental";
    else if (
      routerURLStr == "/toronto" ||
      routerURLStr == "/search/toronto" ||
      routerURLStr.includes("/search/toronto")
    )
      this.globals.statusStr = "Toronto";
    else this.globals.statusStr = '"Active", "Sold"';
    // fork, if the url has changed from active to sold or rental then reload the listings
    if (
      this.globals.previousStatusStr != "" &&
      this.globals.previousStatusStr != this.globals.statusStr
    ) {
      this.resetPaging();
      this.globals.previousStatusStr = this.globals.statusStr;
    } else {
      this.globals.previousStatusStr = this.globals.statusStr;
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.districtList == "ALL OF MUSKOKA") {
      this.globals.districtList = "TORONTO";
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.stateStr == "ON") {
      this.globals.stateStr = "Ontario";
    }
    // set the global min value to 100000 as per Todd's request
    this.globals.searchMin = "1000000";
    // fork, if the listings haven't been already loaded then load them up
    if (this.globals.listings.length == 0) {
      console.log("loading listings");
      this.torontoListingsService.getListings().subscribe(
        (response: any) => {
          //console.log(response.json());
          var dataObj = response as any;
          var listings = dataObj.dataArr as Listing[];
          var countNum = dataObj.countNum;
          this.listings = this.listings.concat(listings);
          this.globals.loadingBln = false;
          this.globals.listings = this.listings;
          //console.log(this.globals);
          // track the event
          this.trackEvent({ _eventStr: "view more listings" });
          // fork, if there are any listings to be displayed then display then load the first liting
          if (this.countNum > 0) this.gotoDetail(listings[0]);
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
    } else {
      this.listings = this.globals.listings;
      this.selectedListing = this.globals.selectedListing;
      this.isSelectedBln = true;
    }
    // fork, if the user has registered then set the property variable
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setScrollTo("resultsList"));
  }

  gotoDetail(listing: Listing): void {
    console.log("direct link clicked");
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
    console.log("init indicator: " + this.globals.initBln);
    console.log("Router URL" + this.router.url);
    this.globals.previousURLStr = this.router.url;
    //console.log('detail indirect');
    this.torontoListingsService
      .getListing(listing.MLSNum, listing.sourceStr)
      //.then(listingsDetails => this.selectedListing = listingsDetails);
      .subscribe(
        (response: any) => {
          var listingsDetails = response as any;
          this.selectedListing = listingsDetails as ListingDetail;
          this.isSelectedBln = true;
          this.globals.selectedListing = listingsDetails;
          console.log("Selected Listing: ");
          console.log(this.isSelectedBln);
          // track the event
          //this.trackEvent({ _eventStr: "preview" });
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
    if (!this.globals.initBln) {
      // add to the viewed counter
      this.globals.listingsViewedNum++;
      console.log("viewed: " + this.globals.listingsViewedNum);
      //console.log("viewed: " + this.listingsViewedNum);
      // fork, if the user has viewed 2 or more listings and has not regstered then show the registration modal
      if (this.globals.listingsViewedNum >= 2 && !this.isRegisteredBln)
        this.showRegistration();
    } else {
      this.globals.initBln = false;
    }
  }

  gotoDetailDirect(listing: Listing): void {
    console.log("direct link clicked");
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
    console.log("init indicator: " + this.globals.initBln);
    console.log("Router URL" + this.router.url);
    // define properties
    var addressHyphenStr = listing.addressHyphenStr;
    var MLSNum = listing.MLSNum;
    var cityHyphenStr = listing.cityHyphenStr;
    var sourceStr = listing.sourceStr;
    console.log(listing);
    var URLStr =
      "https://m.caymanmarshall.com/search/" +
      addressHyphenStr +
      "/res/" +
      MLSNum;
    this.globals.previousURLStr = "search/toronto";
    if (!this.globals.initBln) {
      // add to the viewed counter
      this.globals.listingsViewedNum++;
      console.log("viewed: " + this.globals.listingsViewedNum);
      //console.log("viewed: " + this.listingsViewedNum);
      // fork, if the user has viewed 2 or more listings and has not regstered then show the registration modal
      if (this.globals.listingsViewedNum >= 2 && !this.isRegisteredBln) {
        this.showRegistration();
        return;
      }
    } else {
      this.globals.initBln = false;
    }
    //window.location.href=URLStr;
    //this.router.navigateByUrl('fulldetails/'+MLSNum);
    this.router.navigateByUrl(
      "search/toronto/fulldetails/" +
        addressHyphenStr +
        "/" +
        cityHyphenStr +
        "/" +
        MLSNum +
        "/" +
        sourceStr,
    );
  }

  private clearSubscriptions() {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  public showRegistration() {
    console.log("showing registration");
    console.log("viewed: " + this.globals.listingsViewedNum);
    // define the config options for the modal
    var config = {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    //this.modal.open(this.registrationModal);
    this.clearSubscriptions();
    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {
        //this.messages.push(`onShown event has been fired`);
        //console.log('shown');
      }),
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        //this.messages.push(`onShown event has been fired`);
        //console.log('closed');
        //console.log("Modal Content"+this.bsModalRef.content.isRegisteredBln);
        // set the user registration indicator to stop the registration page from showing up again
        if (this.bsModalRef != undefined)
          this.isRegisteredBln = this.bsModalRef.content.isRegisteredBln;
        //
        //this.bsModalRef=null;
        //this.modalService.onShown=null;
        //this.registrationStepTwo();
      }),
    );
    this.bsModalRef = this.modalService.show(ModalContentComponent, config);
    this.bsModalRef.content.title = "Registration";
  }

  filterListings(searchObj: any) {
    //reset the paging
    this.resetPaging();
    // update the globe search properties
    this.updateGlobalFilterSettings(searchObj);
    // upate the listings
    this.torontoListingsService.filterListings(searchObj).subscribe(
      (response: any) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error: any) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  setScrollTo(nodeIdStr: string) {
    let node = document.getElementById(nodeIdStr);
    // fork, if this is not a mobile version then scroll using the results list or else scroll using the window
    if (!this.globals.mobileBln) {
      if (node) {
        console.log("scroll to ", this.globals.scrollTopNum);
        node.scrollTop = this.globals.scrollTopNum;
      }
    } else {
      console.log("scroll mobile to ", this.globals.scrollTopNum);
      window.scrollTo(0, this.globals.scrollTopNum);
    }
  }

  public onScrollDown() {
    //console.log('scrolled down!!');
    if (this.globals.loadingBln) return;
    if (this.listings.length >= this.globals.countNum) return;
    this.globals.loadingBln = true;
    this.torontoListingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUp() {
    //console.log('scrolled up!!');
  }

  public onScrollDownGlobal() {
    //console.log('scrolled down!!');
    if (this.globals.loadingBln) return;
    this.globals.loadingBln = true;
    this.torontoListingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUpGlobal() {
    //console.log('scrolled up!!');
  }

  public resetPaging() {
    this.globals.limitNum = 10;
    this.globals.startNum = 0;
    this.globals.endNum = 10;
    this.globals.countNum = 0;
    this.listings = [];
    this.globals.listings = [];
  }

  public updateGlobalFilterSettings(searchObj: any) {
    this.globals.propertyTypeStr = searchObj.searchType.value;
    this.globals.districtList = searchObj.area.value;
    this.globals.searchMin = searchObj.priceRangeMin.value;
    this.globals.searchMax = searchObj.priceRangeMax.value;
    this.globals.bedroomsNum = searchObj.bedrooms.value;
    this.globals.bathroomsNum = searchObj.bathrooms.value;
    this.globals.squareFeetStr = searchObj.squareFeet.value;
  }

  public trackEvent(obj: { _eventStr: any }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.generateEventTrackingData(obj),
    };
    // track the even
    this.http.post(this.trackingURLStr, options).subscribe(
      (response) => {
        //console.log(response.json());
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  private generateEventTrackingData(obj: { _eventStr: any }) {
    // define properties
    var emailStr = this.globals.emailStr;
    var userIDStr = this.globals.userIDStr;
    var ipAddrStr = this.globals.ipAddrStr;
    var sidStr = this.globals.sidStr;
    var idStr = this.globals.idStr;
    var eventStr = obj._eventStr;
    var eventFullStr =
      eventStr == "preview"
        ? this.generatePreviewEventFullString()
        : this.generateEventFullString();
    // define properties
    var JSONObj = {
      idStr: idStr,
      emailStr: "",
      userIDStr: "",
      ipAddrStr: ipAddrStr,
      sessionIDStr: "",
      eventStr: eventStr,
      eventFullStr: eventFullStr,
      viewStr: "list-view",
    };
    if (emailStr != "") JSONObj.emailStr = emailStr;
    else delete (JSONObj as any)["emailStr"];
    if (userIDStr != "") JSONObj.userIDStr = userIDStr;
    else delete (JSONObj as any)["userIDStr"];
    if (sidStr != "") JSONObj.sessionIDStr = sidStr;
    else delete (JSONObj as any)["sessionIDStr"];
    // track the analtics event
    this.trackAnalyticsEvent({
      _catStr: "RETS",
      _eventStr: eventStr,
      _labelStr: eventFullStr,
    });
    return JSONObj;
  }

  private generateEventFullString() {
    // define properties
    var cityStr = this.globals.districtList;
    var stateStr = this.globals.stateStr;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var bedroomsNum = this.globals.bedroomsNum;
    var bathroomsNum = this.globals.bathroomsNum;
    var squareFeetStr = this.globals.squareFeetStr;
    var searchMinNum = this.globals.searchMin;
    var searchMaxNum = this.globals.searchMax;
    var eventFullObj = {
      cityStr: cityStr,
      stateStr: stateStr,
      propertyTypeStr: propertyTypeStr,
      bedroomsNum: bedroomsNum,
      bathroomsNum: bathroomsNum,
      squareFeetStr: squareFeetStr,
      searchMin: searchMinNum,
      searchMax: searchMaxNum,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private generatePreviewEventFullString() {
    if (this.selectedListing == undefined) return;
    // define properties
    var MLSNum = this.selectedListing.MLSNum;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var eventFullObj = {
      MLSNum: MLSNum,
      propertyTypeStr: propertyTypeStr,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  private trackAnalyticsEvent(obj: {
    _catStr: any;
    _eventStr: any;
    _labelStr: any;
  }) {
    // define properties
    var catStr = obj._catStr;
    var eventStr = obj._eventStr;
    var labelStr = obj._labelStr;
    var actionStr = "Search Parameter Change";
    // fork, if this is a preview  then set the event action accordingly
    if (eventStr == "preview") actionStr = "Preview Listing";
    ga("send", {
      hitType: "event",
      eventCategory: catStr,
      eventAction: actionStr,
      eventLabel: labelStr,
      hitCallback: function () {
        //alert("Event received");
      },
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
