import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbdCarouselConfig } from "./carousel-config";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CollapseModule } from "ngx-bootstrap/collapse";
//import { ModalModule } from 'ngx-modialog';
//import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';
import { ModalModule } from "ngx-bootstrap/modal";

import { RecaptchaModule } from "ng-recaptcha";

import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { ListingsService } from "./listings.service";
import { SEOService } from "./seo.service";

import { DashboardComponent } from "./dashboard.component";
import { ListingsSearchComponent } from "./listings-search.component";
import { ListingDetailsFullComponent } from "./listing-details-full.component";
import { ContactFormComponent } from "./contact-form.component";

import { TorontoDashboardComponent } from "./toronto-dashboard.component";
import { ModalContentComponent } from "./modal-content.component";
import { TorontoListingsSearchComponent } from "./toronto-listings-search.component";
import { TorontoListingsService } from "./toronto-listings.service";
import { TorontoListingDetailsFullComponent } from "./toronto-listing-details-full.component";

import { PeelHaltonDashboardComponent } from "./peelhalton-dashboard.component";
import { PeelHaltonListingsSearchComponent } from "./peelhalton-listings-search.component";
import { PeelHaltonListingsService } from "./peelhalton-listings.service";
import { PeelHaltonListingDetailsFullComponent } from "./peelhalton-listing-details-full.component";

import { NavigationComponent } from "./navigation.component";
import { NavigationDetailsFullComponent } from "./navigation-details-full.component";
import { FooterComponent } from "./footer.component";
import { Globals } from "./globals";

// GA4 Analytics Services
import { WindowReferenceService } from "./window-reference.service";
import { DataLayerService } from "./data-layer.service";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CollapseModule.forRoot(),
    InfiniteScrollModule,
    RecaptchaModule,
    ScrollToModule.forRoot(),
    ModalModule.forRoot(),
    NgbdCarouselConfig,
    DashboardComponent,
    FooterComponent,
    NavigationComponent,
    NavigationDetailsFullComponent,
    ListingsSearchComponent,
    ListingDetailsFullComponent,
    ContactFormComponent,
    TorontoDashboardComponent,
    PeelHaltonDashboardComponent,
    TorontoListingsSearchComponent,
    PeelHaltonListingsSearchComponent,
    PeelHaltonListingDetailsFullComponent,
    TorontoListingDetailsFullComponent,
    InfiniteScrollModule,
    RouterModule,
    //BootstrapModalModule
  ],
  declarations: [AppComponent, ModalContentComponent],
  providers: [
    ListingsService,
    TorontoListingsService,
    PeelHaltonListingsService,
    Globals,
    SEOService,
    WindowReferenceService,
    DataLayerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
