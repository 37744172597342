<!-- Navigation -->

<nav class="navbar navbar-default">
  <my-navigation></my-navigation>
</nav>

<div class="nav-bg"></div>

<!--<div class="contact-cta">
  <div class="inner-wrapper">
    <h3>Contact</h3>
    <p>Donna Gray: <a href="tel:4162760632">416.276.0632</a></p>
  </div>
</div>-->


<!-- form Section -->
<section id="form" class="forms search-results-page-filters clearfix">
  <my-peelhalton-listing-search (filterListings)="filterListings($event)"></my-peelhalton-listing-search>
  <div class="search-results-totals">
        <span *ngIf="listings">
            <h3>Showing {{ listings.length }} of {{ this.globals.countNum }} properties</h3>
        </span>
  </div>
  <main id="searchResults" class="clearfix"
        infiniteScroll
        [infiniteScrollDistance]="50"
        [infiniteScrollUpDistance]="1"
        [infiniteScrollThrottle]="0"
        (scrolled)="onScrollDownGlobal()"
        (scrolledUp)="onScrollUpGlobal()"
        [scrollWindow]="true"
        [alwaysCallback]="true"
  >
    <div id="resultsList"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollUpDistance]="1"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScrollDown()"
         (scrolledUp)="onScrollUp()"
         [scrollWindow]="false">
        <span *ngIf="currentScreenSize=='XSmall'">
            <div *ngFor="let listing of listings" (click)="gotoDetailDirect(listing)">
                <div class="property active" style="cursor: pointer; ">
                        <div class="featured-image"
                             [ngStyle]="{ 'background-image': 'url(' + listing.mediumThumbnailImageArr + ')', 'cursor': 'pointer' }">
                            <div *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)" class="banner sold">
                                <div class="line">
                                    <span>Sold</span>
                                </div>
                            </div>
                            <div *ngIf="listing.isExclusiveBln" class="banner exclusive">
                                <div class="line">
                                    <span>Exclusive</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && !listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Modified</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Price change</span>
                                </div>
                            </div>
                            <div *ngIf="listing.newBln" class="banner new">
                                <div class="line">
                                    <span>New</span>
                                </div>
                            </div>
                            <div *ngIf="((listing.statusStr | lowercase) =='rental' || (listing.statusStr | lowercase) =='active') && listing.bannerOverrideStr!='' && listing.bannerOverrideStr!='n/a'" class="banner-exclusive-sold sold">
                                <div class="line">
                                    <span [innerHTML]="listing.bannerOverrideStr"></span>
                                </div>
                            </div>
                        </div>
                    <div class="content">
                            <div class="row">
                                <div id="propertyPrice" class="price">{{ listing.listingPriceNum }} <span *ngIf="(listing.statusStr | lowercase)=='rental'">per week</span></div>
                            </div>
                            <div class="row">
                                <p id="propertyDescription" class="description"><span class="sold" *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)">{{ listing.statusStr }}</span><span class="exclusive" *ngIf="listing.isExclusiveBln">Exclusive</span><span *ngIf="listing.sourceStr!='TREB'">{{ listing.addressStr }}</span><span
                                  *ngIf="listing.sourceStr=='TREB'">{{ listing.communityStr }}</span>
                                </p>
                            </div>


                            <div *ngIf="(listing.statusStr | lowercase)!='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bedroomsNum }} Bedrooms</p>
                            </div>
                            <div *ngIf="(listing.statusStr | lowercase)=='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bathroomsNum }} Bathrooms</p>
                            </div>
                            <div class="row">
                                <ul class="links">
                                    <li *ngIf="listing.virtualTourURLStr"><a id="virtualTour" href="{{listing.virtualTourURLStr}}" target="_blank">Virtual Tour</a></li>
                                    <li *ngIf="listing.brochureLinkStr"><a id="brochure" href="{{listing.brochureLinkStr}}" target="_blank">Brochure</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </span>
      <span *ngIf="currentScreenSize=='Small' || currentScreenSize=='Medium'">
            <div *ngFor="let listing of listings" (click)="gotoDetailDirect(listing)">
                <div class="property active" style="cursor: pointer; ">
                        <div class="featured-image"
                             [ngStyle]="{ 'background-image': 'url(' + listing.mediumOptimizedThumbnailImageArr + ')'}">
                            <div *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)" class="banner sold">
                                <div class="line">
                                    <span>Sold</span>
                                </div>
                            </div>
                            <div *ngIf="listing.isExclusiveBln" class="banner exclusive">
                                <div class="line">
                                    <span>Exclusive</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && !listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Modified</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Price change</span>
                                </div>
                            </div>
                            <div *ngIf="listing.newBln" class="banner new">
                                <div class="line">
                                    <span>New</span>
                                </div>
                            </div>
                            <div *ngIf="((listing.statusStr | lowercase) =='rental' || (listing.statusStr | lowercase) =='active') && listing.bannerOverrideStr!='' && listing.bannerOverrideStr!='n/a'" class="banner-exclusive-sold sold">
                                <div class="line">
                                    <span [innerHTML]="listing.bannerOverrideStr"></span>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="row">
                                <div id="propertyPrice" class="price">{{ listing.listingPriceNum }} <span *ngIf="(listing.statusStr | lowercase)=='rental'">per week</span></div>
                            </div>
                            <div class="row">
                                <p id="propertyLocation" class="location">{{ listing.communityStr }}</p>
                            </div>
                            <div class="row">
                                <p id="propertyDescription" class="description"><span class="sold" *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)">{{ listing.statusStr }}</span><span class="exclusive" *ngIf="listing.isExclusiveBln">Exclusive</span><span *ngIf="listing.sourceStr!='TREB'">{{ listing.addressStr }}</span><span
                                  *ngIf="listing.sourceStr=='TREB'">{{ listing.communityStr }}</span>
                                </p>
                            </div>
                            <div *ngIf="(listing.statusStr | lowercase)!='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bedroomsNum }} Bedrooms</p>
                            </div>
                            <div *ngIf="(listing.statusStr | lowercase)=='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bathroomsNum }} Bathrooms</p>
                            </div>
                            <div class="row">
                                <ul class="links">
                                    <li *ngIf="listing.virtualTourURLStr"><a id="virtualTour" href="{{listing.virtualTourURLStr}}" target="_blank">Virtual Tour</a></li>
                                    <li *ngIf="listing.brochureLinkStr"><a id="brochure" href="{{listing.brochureLinkStr}}" target="_blank">Brochure</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </span>
      <span *ngIf="currentScreenSize=='Large' || currentScreenSize=='XLarge'">
            <div *ngFor="let listing of listings" (click)="gotoDetail(listing)">
                <div class="property active">
                        <div class="featured-image"
                             [ngStyle]="{ 'background-image': 'url(' + listing.mediumThumbnailImageArr + ')', 'cursor': 'pointer'}">
                            <div *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)" class="banner sold">
                                <div class="line">
                                    <span>Sold</span>
                                </div>
                            </div>
                            <div *ngIf="listing.isExclusiveBln" class="banner exclusive">
                                <div class="line">
                                    <span>Exclusive</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && !listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Modified</span>
                                </div>
                            </div>
                            <div *ngIf="listing.modifiedBln && !listing.newBln && listing.isPriceChangeBln" class="banner change">
                                <div class="line">
                                    <span>Price change</span>
                                </div>
                            </div>
                            <div *ngIf="listing.newBln" class="banner new">
                                <div class="line">
                                    <span>New</span>
                                </div>
                            </div>
                            <div *ngIf="((listing.statusStr | lowercase) =='rental' || (listing.statusStr | lowercase) =='active') && listing.bannerOverrideStr!='' && listing.bannerOverrideStr!='n/a'" class="banner-exclusive-sold sold">
                                <div class="line">
                                    <span [innerHTML]="listing.bannerOverrideStr"></span>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="row">
                                <div id="propertyPrice" class="price">{{ listing.listingPriceNum }} <span *ngIf="(listing.statusStr | lowercase)=='rental'">per week</span></div>
                            </div>
                            <div class="row">
                                <p id="propertyDescription" class="description"><span class="sold" *ngIf="((listing.statusStr | lowercase)=='sold' && !listing.isExclusiveBln)">{{ listing.statusStr }}</span><span class="exclusive" *ngIf="listing.isExclusiveBln">Exclusive</span><span *ngIf="listing.sourceStr!='TREB'">{{ listing.addressStr }}</span><span
                                  *ngIf="listing.sourceStr=='TREB'">{{ listing.communityStr }}</span>
                                </p>
                            </div>
                            <div *ngIf="(listing.statusStr | lowercase)!='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bedroomsNum }} Bedrooms</p>
                            </div>
                            <div *ngIf="(listing.statusStr | lowercase)=='rental'" class="row">
                                <p id="propertyFeet" class="feet">{{ listing.bathroomsNum }} Bathrooms</p>
                            </div>
                            <div class="row">
                                <ul class="links">
                                    <li *ngIf="listing.virtualTourURLStr"><a id="virtualTour" href="{{listing.virtualTourURLStr}}" target="_blank">Virtual Tour</a></li>
                                    <li *ngIf="listing.brochureLinkStr"><a id="brochure" href="{{listing.brochureLinkStr}}" target="_blank">Brochure</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>
        </span>
      <div class="end-of-list" *ngIf="listings.length < this.globals.countNum">
        <p>Your results are loading.</p>
      </div>
    </div>
    <div id="selectedProperty" *ngIf="isSelectedBln == true && selectedListing!=undefined"
         infiniteScroll
         [scrollWindow]="false">
      <div class="active-property">
        <div class="gallery">
          <ngbd-carousel-config [imageArr]="selectedListing.thumbnailOptimizedArr" [fullImageArr]="selectedListing.imageArr" [addressHyphenStr]="selectedListing.addressHyphenStr" [cityHyphenStr]="selectedListing.cityHyphenStr" [MLSNum]="selectedListing.MLSNum"></ngbd-carousel-config>

        </div>
        <div class="content">
          <div id="propertyPrice" class="price">{{ selectedListing.listingPriceFullStr }} <span *ngIf="(selectedListing.statusStr | lowercase)=='rental'">per week</span></div>
          <div class="row">
            <p id="propertyLocation" class="location">{{ selectedListing.cityStr }}</p>
          </div>
          <div class="row">
            <p id="propertyDescription" class="description"><span *ngIf="selectedListing.sourceStr!='TREB'">{{ selectedListing.addressStr }}</span><span *ngIf="selectedListing.sourceStr=='TREB'">{{ selectedListing.communityStr }}</span></p>
          </div>
          <div *ngIf="(selectedListing.statusStr | lowercase)!='rental'" class="row">
            <p id="propertyFeet" class="feet">{{ selectedListing.squareFeetStr }} sq. FT.</p>
          </div>
          <div *ngIf="(selectedListing.statusStr | lowercase)=='rental'" class="row">
            <p id="propertyFeet" class="feet">{{ selectedListing.bedroomsNum }} Bedrooms</p>
          </div>
          <div *ngIf="(selectedListing.statusStr | lowercase)=='rental'" class="row">
            <p id="propertyFeet" class="feet">{{ selectedListing.bathroomsFullNum }} Bathrooms</p>
          </div>
          <div class="row">
            <ul class="links">
              <li *ngIf="selectedListing.virtualTourStr"><a id="virtualTour" href="{{selectedListing.virtualTourStr}}" target="_blank">Virtual Tour</a></li>
              <li *ngIf="selectedListing.brochureLinkStr"><a id="brochure" href="{{selectedListing.brochureLinkStr}}" target="_blank">Brochure</a></li>
            </ul>
          </div>
          <div class="row">
            <!--<a id="viewFullDetails" class="full-details-link" href="http://m2.caymanmarshall.com/fulldetails/{{selectedListing.MLSNum}}" target="_blank">View full details</a>-->
            <a id="viewFullDetails" class="full-details-link" [routerLink]="'fulldetails/' + selectedListing.addressHyphenStr + '/' + selectedListing.cityHyphenStr + '/' + selectedListing.MLSNum + '/' + selectedListing.sourceStr">View Full Details</a>
          </div>
          <div class="row">
            <h3 class="description-heading">Description</h3>
          </div>
          <div class="row">
            <p class="description">{{ selectedListing.shortDescriptionStr }}</p>
          </div>
          <br>
          <br>
          <div class="row">
            <p id="propertyFeet" class="feet">Listing courtesy of  {{ selectedListing.listingOfficeNameStr }}</p>
          </div>
          <div class="row">
            <ul class="features">
              <li id="feet">{{ selectedListing.squareFeetStr }} Sq. Ft.</li>
              <li id="acres" *ngIf="selectedListing.sourceStr!='TREB'">{{ selectedListing.lotSizeStr }}</li>
              <li *ngIf="selectedListing.bedroomsNum!='0'" id="bedrooms">{{ selectedListing.bedroomsNum }} Bedrooms</li>
              <li *ngIf="selectedListing.bathroomsFullNum!='0'" id="bathrooms">{{ selectedListing.bathroomsFullNum }}
                <span *ngIf="selectedListing.bathroomsHalfNum!='0'">+ {{ selectedListing.bathroomsHalfNum }}</span>
                Bathrooms
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</section>
