<footer>
  <div class="container">

    <div class="row bottom-push">
      <div class="col-md-4">
        <div class=""><img src="https://dhauv7lm2gh1r.cloudfront.net/cm-mobile/img/logo-footer.png"
                           alt="Cayman Marshall International"></div>

      </div>
    </div>

  </div>
  <div class="container">

    <div class="row bottom-push">
      <div class="col-md-4">
        <h4 class="">Oakville/Mississauga</h4>
        <h5 class="normal">132 Traflagar Rd., Suite 21</h5>
        <h5 class="normal">Oakville ON, L6J 3G5</h5>
        <h5 class="normal">Office: <a href="tel:4162760632">416.276.0632</a></h5>
        <h5 class="normal">Email: <a href="mailto:donna@caymanmarshall.com">donna&#64;caymanmarshall.com</a></h5>
      </div>
      <div class="col-md-4">
        <h4 class="">North Muskoka</h4>
        <h5 class="normal">20 Main St. W</h5>
        <h5 class="normal">Huntsville ON, P1H 2C3</h5>
        <h5 class="normal">Office: <a href="tel:7057871611">705.787.1611</a></h5>
        <h5 class="normal">Fax: <a href="tel:7057871632">705.787.1632</a></h5>
        <h5 class="normal">Email: <a href="mailto:info@caymanmarshall.com">info&#64;caymanmarshall.com</a></h5>
      </div>
      <div class="col-md-4">
        <h4 class="">South Muskoka</h4>
        <h5 class="normal">97B Joseph St. Unit 202</h5>
        <h5 class="normal">Port Carling ON, P0B 1J0</h5>
        <h5 class="normal">Office: <a href="tel:7057655759">705.765.5759</a></h5>
        <h5 class="normal">Fax: <a href="tel:7057655737">705.765.5737</a></h5>
        <h5 class="normal">Email: <a href="mailto:info@caymanmarshall.com">info&#64;caymanmarshall.com</a></h5>
      </div>
      <div class="col-md-4">
        <h4 class="">Toronto</h4>
        <h5 class="normal">99 Yorkville Avenue, Suite 200</h5>
        <h5 class="normal">Toronto ON, M5R 3K5</h5>
        <h5 class="normal">Office: <a href="tel:6476964733">647.696.4733</a></h5>
        <h5 class="normal">Fax: <a href="tel:6476964735">647.696.4735</a></h5>
        <h5 class="normal">Email: <a href="mailto:info@caymanmarshall.com">info&#64;caymanmarshall.com</a></h5>
      </div>
    </div>

  </div>

  <div class="lighter">
    <div class="container">


      <div class="row top-push bottom-push">
        <div class="col-md-4 right  footer-subheading">Connect With Us</div>
        <div class="col-md-2">
          <ul class="list-inline social-buttons pull-right">
            <li><a href="https://www.facebook.com/CaymanMarshallRealty/" target="_blank"><i class="fa fa-facebook"></i></a>
            </li>
            <li><a href="https://www.instagram.com/caymanmarshallrealty/" target="_blank"><i class="fa fa-instagram"></i></a>
            </li>
            <li><a href="mailto:info@caymanmarshall.com" target="_blank"><i class="fa fa-envelope"></i></a>
            </li>
          </ul>
        </div>

      </div>

    </div>
  </div>

  <div class="container">

    <div class="row">
      <div class="col-md-6">
        <span class="copyright">Copyright &copy; 2018 Cayman Marshall International Brokerage Inc. All rights reserved. The intent of this communication is for informational purposes only and is not intended to be a solicitation to anyone under contract with another real estate brokerage organization.</span>
      </div>

      <div class="col-md-6">
        <ul class="list-inline quicklinks pull-right">
          <li><a href="javascript:;" (click)="openModal(privacypolicy)">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>


  </div>
</footer>
<ng-template #privacypolicy>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Privacy Policy</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Thank you for visiting our site!</p>

    <p>We are committed to respecting your right to privacy and will protect it when you visit our website.</p>

    <p>This privacy policy explains our online information practices only, including how we collect and use your personal information. It covers information collected on this website only. It does not apply to other sites you reach through our site. We encourage you to read the Privacy Policies of those other parties to learn how they collect and use information about you.</p>

    <p>To assist you with reading our privacy policy, we have provided answers to the following questions you may find most important:</p>

    <p>What information do we collect automatically online?</p>

    <p>When you visit our website, we collect information about your visit that does not identify you personally. We can tell the type of computer, browser, and Web service you are using. We also know the date, time, and pages you visit. Collecting this information helps us design the site to suit your needs. If you are registered with our site, we will try to automatically identify you so that
      you don't have to sign-in upon each return visit.</p>

    <p>What additional information do we collect about you online?</p>

    <p>If you ask us a question or register with this site, we collect your contact information and additional information that you volunteer so that we can respond to your request. We'll track your online activity and the information we collect is used to improve the content of our Web pages, used to customize the content and/or layout of our pages to optimize your website experience.</p>

    <p>If you supply us with your telephone number online you may receive telephone contact from us with information regarding your visit to our website. If you do not wish to receive such telephone calls, please let us know by responding to the initial email that you receive from us.</p>

    <p>Do we share the information we collect online?</p>

    <p>We do not share any information about you unless we are required by law to do so for authorized law enforcement.</p>

    <p>How do we handle email communication?</p>

    <p>If you provide us with your email address and you choose to correspond with us via email, we may choose to store these correspondences together with your registration information. Occasionally, we may also send you email notifications relating to new offers that we feel that you might find valuable. If at any time you wish to stop receiving these offers, you may click the unsubscribe link
      on any of these communications to opt-out or email us back directly to request your email address be removed from our database.</p>

    <p>Do we use cookies or other tracking devices?</p>

    <p>A "cookie" is a small text file that is stored on your computer and makes it easy for you to move around a website without having to continually re-enter your name, password, preferences, and so on. We use cookies to store visitors' preferences, record user-specific information on what pages users access or visit, customize Web page content based on visitors' browser type or other
      information that the visitor sends.</p>

    <p>What if I am on the Do Not Call Registry?</p>

    <p>If you supply us with a phone number, then you are giving us permission to call you even if you are on the Do Not Call Registry. If you choose not to be bothered, please let us know. We will add you to our own Do Not Call list.</p>

    <p>Opt-out</p>

    <p>You may stop recieving all email communications from us. <a href="https://www.caymanmarshall.com/unsubscribe.php" target="_blank">Click here to unsubscribe.</a></p>

    <p>This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply
    </p>
  </div>
</ng-template>
