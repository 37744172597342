import { Injectable } from "@angular/core";
import { WindowReferenceService } from "./window-reference.service";

@Injectable()
export class DataLayerService {
  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  private pingHome(obj: {
    event: string;
    pageName?: any;
    customEventCategory?: any;
    customEventAction?: string;
    customEventLabel?: any;
    value?: any;
  }) {
    if (obj) this.window.dataLayer.push(obj);
  }

  //list of all our dataLayer methods

  logPageView(url: any) {
    const hit = {
      event: "content-view",
      pageName: url,
    };
    this.pingHome(hit);
  }

  logEvent(event: string, category: any, action: string, label: any) {
    const hit = {
      event: event,
      customEventCategory: category,
      customEventAction: action,
      customEventLabel: label,
    };
    console.log(hit);
    this.pingHome(hit);
  }

  logCustomDimensionTest(value: any) {
    const hit = {
      event: "custom-dimension",
      value: value,
    };
    this.pingHome(hit);
  }

  // .. add more custom methods as needed by your app.
}
