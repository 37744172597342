@if (contactForm) {
  <div class="row">
    <div class="col-lg-12 text-center">
      <h2 class="section-heading">Inquire About This Property</h2>

      <h3 class="section-subheading text-muted">Please fill out the form below.</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <form [formGroup]="contactForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input id="name" class="form-control" placeholder="Your name *" formControlName="name">

              <div *ngIf="name!=null && name.invalid  && (name.dirty || name.touched)"
                   class="alert alert-danger">
                <div>test</div>
                <div *ngIf="name.hasError('required')">
                  Please enter your name.
                </div>
                <div *ngIf="name.hasError('minlength')">
                  Name must be at least 4 characters long.
                </div>
              </div>
            </div>
            <div class="form-group">
              <input id="email" class="form-control" placeholder="Your Email *" formControlName="email">

              <div *ngIf="email!=null && email.invalid && (email.dirty || email.touched)"
                   class="alert alert-danger">
                <div *ngIf="email.hasError('required')">
                  Please enter your email address.
                </div>
                <div *ngIf="email.hasError('email')">
                  Please enter a valid email address.
                </div>
              </div>
            </div>
            <div class="form-group">
              <input id="phone" class="form-control" placeholder="Your Phone *" formControlName="phone">

              <div *ngIf="phone!=null && phone.invalid && (phone.dirty || phone.touched)"
                   class="alert alert-danger">

                <div *ngIf="phone.hasError('required')">
                  Please enter your phone number.
                </div>
                <div *ngIf="phone.hasError('phone')">
                  phone must be at least 4 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <textarea class="form-control" placeholder="Your Message *" id="message" formControlName="message"></textarea>

              <div *ngIf="message!=null && message.invalid && (message.dirty || message.touched)"
                   class="alert alert-danger">

                <div *ngIf="message.hasError('required')">
                  Please enter a message.
                </div>
                <div *ngIf="message.hasError('minlength')">
                  Message must be at least 4 characters long.
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div class="col-lg-12 text-center">
            <div id="success" *ngIf="successBln && !dismissAlertBln">
              <div class='alert alert-success'>Your message has been sent.
                <button type='button' class='close' data-dismiss='alert' aria-hidden='true' (click)="dismissAlert();">&times;</button>
              </div>

            </div>
            <div [hidden]="submittedBln">
              <button type="submit" class="btn btn-xl opacity-100 text-white " [disabled]="contactForm.invalid"
                      (click)="submitContactForm();">Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
}
