import {NgModule} from '@angular/core';
import {Routes, RouterModule, RouterLinkActive, Router } from '@angular/router';

import {DashboardComponent} from './dashboard.component';

import {TorontoDashboardComponent} from './toronto-dashboard.component';
import {PeelHaltonDashboardComponent} from './peelhalton-dashboard.component';
import {ListingDetailsFullComponent} from './listing-details-full.component';
import {TorontoListingDetailsFullComponent} from './toronto-listing-details-full.component';
import {PeelHaltonListingDetailsFullComponent} from './peelhalton-listing-details-full.component';
import {Globals} from './globals';

const routes: Routes = [
    // { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {path: '', component: PeelHaltonDashboardComponent },
    {path: 'fulldetails/:MLSNum', component: PeelHaltonListingDetailsFullComponent },
    {path: 'sold/fulldetails/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'rentals/fulldetails/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'fulldetails/:addressStr/:cityStr/:MLSNum/:sourceStr', component: PeelHaltonListingDetailsFullComponent },
    {path: 'sold/fulldetails/:addressStr/:cityStr/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'rentals/fulldetails/:addressStr/:cityStr/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'sold', component: DashboardComponent},
    {path: 'rentals', component: DashboardComponent},
    {path: 'search', component: PeelHaltonDashboardComponent },
    {path: 'search/toronto', component: TorontoDashboardComponent },
    {path: 'search/toronto/fulldetails/:addressStr/:cityStr/:MLSNum/:sourceStr', component: TorontoListingDetailsFullComponent },
    {path: 'search/fulldetails/:addressStr/:cityStr/:MLSNum/:sourceStr', component: PeelHaltonListingDetailsFullComponent },
    {path: 'search/fulldetails/:addressStr/:cityStr/:MLSNum', component: PeelHaltonListingDetailsFullComponent },
    {path: 'search/sold/fulldetails/:addressStr/:cityStr/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'search/rentals/fulldetails/:addressStr/:cityStr/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'search/fulldetails/:MLSNum', component: PeelHaltonListingDetailsFullComponent },
    {path: 'search/sold/fulldetails/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'search/rentals/fulldetails/:MLSNum', component: ListingDetailsFullComponent },
    {path: 'search/sold', component: DashboardComponent},
    {path: 'search/rentals', component: DashboardComponent},
    {path: 'search/rentals/', component: DashboardComponent},
    {path: 'search/:addressStr/:cityStr/:MLSNum', component: PeelHaltonListingDetailsFullComponent},
    //{ path: 'detail/:MLSNum', component: ListingDetailComponent },
    //{ path: 'listings', component: ListingsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        { enableTracing: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {

    constructor(globals: Globals, private router: Router) {
        //console.log(router);
    }
}
