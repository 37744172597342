<!-- Brand and toggle get grouped for better mobile display -->
<div class="navbar-header page-scroll">
  <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" (click)="isCollapsed = !isCollapsed" *ngIf="globals.linksEnabledBln">
    <span class="sr-only">Toggle navigation</span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
    <span class="icon-bar"></span>
  </button>
  <a class="navbar-brand page-scroll" href="http://www.{{globals.hostnameStr}}"><img src="https://cndrep.s3.us-west-2.amazonaws.com/cm-mobile/img/cm-south-web-logo.png" alt="Cayman Marshall International" class="resize-logo"></a>
</div>

<!-- Collect the nav links, forms, and other content for toggling -->
<div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" (collapsed)="collapsed($event)"
     (expanded)="expanded($event)"
     [collapse]="isCollapsed" *ngIf="globals.linksEnabledBln">
  <ul class="nav navbar-nav navbar-right">
    <li><a href="http://www.oakvillehomerealestate.net" (click)="collapseMenu()">Home</a></li>
    <li *ngIf="currentScreenSize=='Handset' || currentScreenSize=='Tablet'" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><a [routerLink]="['/search']" href="http://m.oakvillehomerealestate.net/search" (click)="collapseMenu()"> Featured Listings - Peel/Halton</a></li>
    <li *responsive="currentScreenSize=='Handset' || currentScreenSize=='Tablet'" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><a [routerLink]="['/search/toronto']" href="http://m.oakvillehomerealestate.net/search/toronto" (click)="collapseMenu()"> Featured Listings - Toronto</a></li>
    <li *responsive="currentScreenSize=='Web'" class="has-children" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"><a href="javascript:void(0);" (click)="collapseMenu()">Featured Listings</a>
      <ul class="sub-menu">
        <li class="sub-menu__item"><a [routerLink]="['/search']" (click)="collapseMenu()">Peel/Halton</a></li>
        <li class="sub-menu__item"><a [routerLink]="['/search/toronto']" (click)="collapseMenu()">Toronto</a></li>
      </ul>
    </li>
    <li><a href="http://www.oakvillehomerealestate.net/associates" (click)="collapseMenu()">Associates</a></li>
    <li><a href="http://www.oakvillehomerealestate.net/buying" (click)="collapseMenu()">Buying</a></li>
    <li><a href="http://www.oakvillehomerealestate.net/selling" (click)="collapseMenu()">Selling</a></li>
    <li class="has-children"><a [routerLink]="" (click)="collapseMenu()">Media</a>
      <ul class="sub-menu">
        <li class="sub-menu__item"><a href="http://www.oakvillehomerealestate.net/magazines" (click)="collapseMenu()">Magazines</a></li>
      </ul>
    </li>
    <li><a href="http://www.oakvillehomerealestate.net/company" (click)="collapseMenu()">About Us</a></li>

  </ul>
</div>
<!-- /.navbar-co  llapse -->
