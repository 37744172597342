/* tslint:disable: member-ordering forin */
import {Component, Input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

import {Contact} from "./contact";
import {ListingDetail} from "./listing-detail";

@Component({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  standalone: true,
  selector: "contact-form",
  templateUrl: "./contact-form.component.html",
})
export class ContactFormComponent implements OnInit {
  @Input() selectedListing: ListingDetail | undefined;

  model = new Contact("", "", "", "");

  contactForm: FormGroup | undefined;

  private contactURLStr = "/mail/contact_me.php";

  submittedBln = false;

  successBln = false;

  dismissAlertBln = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    let nameControl = new FormControl(this.model.name, [
      Validators.required,
      Validators.minLength(4),
    ]);
    this.contactForm = new FormGroup({
      name: nameControl,
      email: new FormControl(this.model.email, [
        Validators.required,
        Validators.email,
      ]),
      phone: new FormControl(this.model.phone, [
        Validators.required,
        Validators.minLength(4),
      ]),
      message: new FormControl(this.model.message, [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
    nameControl.valueChanges.subscribe((value) => {
      console.log(nameControl.invalid);
      console.log("New value:", value);
    });
    //console.log(this.contactForm);
  }

  public submitContactForm() {
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.generateContactData(),
    };

    let paramsObj = this.generateContactData();
    let params = new HttpParams({
      fromObject: paramsObj,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    this.submittedBln = true;
    // track the even
    this.http
      .post(this.contactURLStr, params.toString(), httpOptions)
      .subscribe(
        (response) => {
          this.successBln = true;
        },
        (error) => {
          this.handleError(error); // Centralized error handling
        },
      );
  }

  private generateContactData() {
    // define properties
    let sourceStr =
      this.selectedListing != undefined ? this.selectedListing.sourceStr : "";
    let MLSNUM =
      this.selectedListing != undefined ? this.selectedListing.MLSNum : "";
    var JSONObj = {
      name: this.name != null ? this.name.value : "",
      email: this.email != null ? this.email.value : "",
      phone: this.phone != null ? this.phone.value : "",
      message: this.message != null ? this.message.value : "",
      referStr: "CM",
      sourceStr: sourceStr,
      MLSNUM: MLSNUM,
    };
    return JSONObj;
  }

  public dismissAlert() {
    console.log("dismissing alert");
    this.dismissAlertBln = true;
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  get name() {
    // @ts-ignore
    return this.contactForm.get("name");
  }

  get email() {
    // @ts-ignore)
    return this.contactForm.get("email");
  }

  get phone() {
    // @ts-ignore
    return this.contactForm.get("phone");
  }

  get message() {
    // @ts-ignore
    return this.contactForm.get("message");
  }
}
