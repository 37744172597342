import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import {
  NgbCarouselConfig,
  NgbCarouselModule,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgbCarouselModule,
    RouterModule,
    CommonModule,
    NgbCarouselModule,
  ],
  selector: "ngbd-carousel-config",
  templateUrl: "carousel-config.html",
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class NgbdCarouselConfig implements OnChanges {
  @Input() imageArr: String[] | undefined;
  @Input() fullImageArr: any[] | undefined;
  @Input() addressHyphenStr: string | undefined;
  @Input() cityHyphenStr: string | undefined;
  @Input() MLSNum: string | undefined;
  images = [700, 533, 807, 124].map(
    (n) => `https://picsum.photos/id/${n}/900/500`,
  );

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

    console.log(this.imageArr);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes being made");
    console.log(changes);
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  replaceImage(obj: { _elementObj: any; _indexNum: any }) {
    // define properties
    var elementObj = obj._elementObj;
    var indexNum = obj._indexNum;

    console.log(elementObj);
  }
}
