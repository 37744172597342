<div *ngIf="loadedBln">
  <!-- Navigation -->
  <nav class="navbar navbar-default navbar-fixed-top">
    <my-navigation></my-navigation>
  </nav>
  <div id="top"></div>

  <div class="contact-cta">
    <div class="inner-wrapper">
      <h3>Contact</h3>
      <p>Office: <a href="tel:7057871611">705.787.1611</a></p>
      <p>TF: <a href="tel:8447871612">844.787.1612</a></p>
      <p>Todd Adair: <a href="tel:7057878584">705-787-8584</a></p>
    </div>
  </div>
  <!-- Header -->

  <div *ngIf="selectedListing!=undefined">
    <span *responsive="{sizes: {min: 0, max: 822}}">
        <header [style.background-image]="getBackground(selectedListing.thumbnailArr[0])">
            <div class="container">
                <div class="intro-text">
                    <div class="intro-lead-in">Listed For {{ selectedListing.listingPriceFullStr }}</div>
                    <div class="intro-heading">{{ selectedListing.addressStr }}</div>


                    <div *ngIf="selectedListing.virtualTourStr!=''">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.virtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">Virtual Tour</a>
                    </div>
                    <div *ngIf="selectedListing.threeDVirtualTourStr!='' && selectedListing.threeDVirtualTourStr!=null && selectedListing.threeDVirtualTourStr!='n/a'">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.threeDVirtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">3D Virtual Tour</a>
                    </div>
                    <a *ngIf="selectedListing.statusStr!='Rental'" href="javascript:;" [ngxScrollTo]="'gallery'" class="page-scroll btn btn-xl">View More</a>
                    <a *ngIf="selectedListing.statusStr=='Rental'" href="mailto:rentals@caymanmarshall.com?subject=Booking for {{selectedListing.MLSNum}} - {{selectedListing.addressStr}}" class="page-scroll btn btn-xl">Book It!</a>
                </div>
            </div>
        </header>
    </span>

    <span *responsive="{sizes: {min: 822, max: 10000}}">
        <header [style.background-image]="getBackground(selectedListing.imageArr[0])">
            <div class="container">
                <div class="intro-text">
                    <div class="intro-lead-in">Listed For {{ selectedListing.listingPriceFullStr }}</div>
                    <div class="intro-heading">{{ selectedListing.addressStr }}</div>


                    <div *ngIf="selectedListing.virtualTourStr!=''">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.virtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">Virtual Tour</a>
                    </div>
                    <div *ngIf="selectedListing.threeDVirtualTourStr!='' && selectedListing.threeDVirtualTourStr!=null && selectedListing.threeDVirtualTourStr!='n/a'">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.threeDVirtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">3D Virtual Tour</a>
                    </div>
                    <a *ngIf="selectedListing.statusStr!='Rental'" href="javascript:;" [ngxScrollTo]="'gallery'" class="page-scroll btn btn-xl">View More</a>
                    <a *ngIf="selectedListing.statusStr=='Rental'" href="mailto:rentals@caymanmarshall.com?subject=Booking for {{selectedListing.MLSNum}} - {{selectedListing.addressStr}}" class="page-scroll btn btn-xl">Book It!</a>
                </div>
            </div>
        </header>
    </span>

    <!-- gallery Grid Section -->
    <section id="gallery" class="bg-light-gray">
      <div class="container">
        <div class="sub-nav">
          <my-navigation-details-full [selectedListing]="selectedListing"></my-navigation-details-full>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">Gallery</h2>

            <h3 class="section-subheading text-muted">Explore This Beautiful Property</h3>
          </div>
        </div>

        <div class="row">

          <div *ngFor="let image of selectedListing.imageArr; let i = index;" class="col-md-4 col-sm-6 gallery-img" style="width: 380px; height: 219px;">
            <a href="{{image}}" class="" data-lightbox="cm-gallery">
              <img src="{{selectedListing.thumbnailArr[i]}}" class="img-responsive" alt="" style="height: 219px;"></a>
          </div>


        </div>
      </div>
    </section>

    <!-- Details Section -->
    <section id="details">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">

            <h2 class="section-heading">Details</h2>

            <h3 class="">{{ selectedListing.addressStr }}</h3>
            <h4 class="subtext">Listed For {{ selectedListing.listingPriceFullStr }}</h4><h4 class="">
            {{ selectedListing.cityStr }}</h4>

            <h5 class="bottom-push"><span class="text-muted thin">ID#</span> {{ selectedListing.MLSNum }} |
              <span class="text-muted thin">Status</span> {{ selectedListing.statusStr }}</h5>

            <div class="row">

              <div class="col-md-2 col-md-offset-2" *ngIf="selectedListing.bedroomsNum!='' && selectedListing.bedroomsNum!='0'">
                <h2 class="section-heading text-muted">
                  {{ selectedListing.bedroomsNum }}</h2>

                <h3 class="section-subtext">Bedrooms</h3>
              </div>
              <div class="col-md-2" *ngIf="selectedListing.bathroomsFullNum!='' && selectedListing.bathroomsFullNum!='0'">
                <h2 class="section-heading text-muted">
                  {{ selectedListing.bathroomsFullNum }}</h2>
                <h3 class="section-subtext">Bathrooms</h3>
              </div>
              <div class="col-md-2" *ngIf="selectedListing.dynamicFieldNameStr!='' && selectedListing.dynamicFieldValStr!=''">
                <h2 class="section-heading text-muted">
                  {{ selectedListing.dynamicFieldValStr }}</h2>
                <h3 class="section-subtext">{{ selectedListing.dynamicFieldNameStr }}</h3>
              </div>

              <span *ngIf="(selectedListing.statusStr | lowercase)!='rental'" class="row">
                            <div class="col-md-2" *ngIf="selectedListing.lotSizeStr!=''">
                                <h2 class="section-heading text-muted">{{ selectedListing.lotSizeStr }}</h2>
                                <h3 class="section-subtext">Acres</h3>
                            </div>
                            <div class="col-md-2" *ngIf="selectedListing.squareFeetStr!='' && selectedListing.squareFeetStr!='0'">
                                <h2 class="section-heading text-muted">{{ selectedListing.squareFeetStr }}</h2>

                                <h3 class="section-subtext">Sq. Ft.</h3>
                            </div>
                        </span>

            </div>

            <h3 class="top-push">Property Description</h3>

            <p class="top-push" [innerHTML]="selectedListing.descriptionStr"></p>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">

          </div>
        </div>
      </div>
    </section>

    <!-- Contact Section -->
    <section id="contact">
      <div class="container">
        <contact-form [selectedListing]="selectedListing"></contact-form>
      </div>
    </section>
  </div>

  <my-footer></my-footer>
</div>
