import { Component, inject, Input, OnInit } from "@angular/core";
import { CommonModule, Location } from "@angular/common";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { Globals } from "./globals";
import { ListingDetail } from "./listing-detail";
import { Subject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

@Component({
  standalone: true,
  imports: [CommonModule, ScrollToModule],
  selector: "my-navigation-details-full",
  templateUrl: "./navigation-details-full.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class NavigationDetailsFullComponent implements OnInit {
  public isCollapsed: boolean = true;
  @Input() selectedListing: ListingDetail | undefined;
  public activeStr = "top";
  private scrollExecuted: boolean = false;
  public fallbackURLStr: string = "https://m.caymanmarshall.com/search";
  destroyed = new Subject<void>();
  currentScreenSize: string | undefined;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, "XSmall"],
    [Breakpoints.Small, "Small"],
    [Breakpoints.Medium, "Medium"],
    [Breakpoints.Large, "Large"],
    [Breakpoints.XLarge, "XLarge"],
  ]);

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private http: HttpClient,
    private globals: Globals,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? "Unknown";
            console.log("screen size: " + this.currentScreenSize);
          }
        }
      });
    router.events.subscribe((s) => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          this.gotoHashtag(tree.fragment);
        }
      }
    });
  }

  ngOnInit(): void {
    //console.log('hello');
  }

  public goBack() {
    console.log(this.globals);
    // define properties
    var previousURLStr = this.globals.previousURLStr;
    //this.resetPaging();
    console.log(this.globals.previousURLStr);
    // fork, if the previous url is not blank then use the browser history or else do a hard redirect
    if (previousURLStr != "") this._location.back();
    else window.location.href = this.fallbackURLStr;
  }

  public goBackDirect() {
    var previousStatusStr = this.globals.previousStatusStr;
    console.log("Previous Status: " + previousStatusStr);
    if (previousStatusStr != "") {
      switch (previousStatusStr) {
        case '"Active", "Sold"':
          this.router.navigateByUrl("/search");
          break;
        case "Sold":
          this.router.navigateByUrl("/search/sold");
          break;
        case "Rental":
          this.router.navigateByUrl("/search/rentals");
          break;
        case "Toronto":
          this.router.navigateByUrl("/search/toronto");
          break;
      }
    } else {
      this.router.navigateByUrl("/search");
    }
  }

  public resetPaging() {
    this.globals.limitNum = 10;
    this.globals.startNum = 0;
    this.globals.endNum = 10;
    this.globals.countNum = 0;
  }

  gotoHashtag(fragment: string) {
    console.log(fragment);
    let el = document.getElementById("#" + fragment);
    if (el == null) return;
    el.scrollIntoView({ behavior: "smooth" });
  }

  redirect(idStr: string) {
    this.router.navigate(["./" + idStr]);
  }

  public collapsed(event: any): void {
    //console.log('collapsed');
    //console.log(this.isCollapsed);
    //console.log(event);
  }

  public expanded(event: any): void {
    //console.log('expanded');
    //console.log(this.isCollapsed);
    //console.log(event);
  }

  public collapseMenu() {
    //console.log('collapse menu');
    this.isCollapsed = true;
  }

  public trackAnalyticsEvent(obj: { _eventStr: any }) {
    // define properties
    var analyticsObj = this.setupAnalyticsEvent(obj);
    console.log(analyticsObj);
    (<any>window).dataLayer.push(analyticsObj);
  }

  private setupAnalyticsEvent(obj: { _eventStr: any }) {
    if (this.selectedListing == undefined) return;
    1;
    // define properties
    var eventStr = obj._eventStr;
    var catStr = "RETS";
    var actionStr = "";
    var MLSNum = this.selectedListing.MLSNum;
    var addressStr = this.selectedListing.addressStr;
    var labelStr = "MLS Number: " + MLSNum + " | Address: " + addressStr;

    // switch, based on the selected event create a full analytics action
    switch (eventStr) {
      case "gallery-click":
        // set the properties
        actionStr = "View Gallery | " + addressStr;
        break;
      case "details-click":
        actionStr = "View Details | " + addressStr;
        break;
      case "request-more-info-click":
        actionStr = "Request Info | " + addressStr;
        break;
    }
    return {
      event: eventStr,
      customEventCategory: catStr,
      customEventAction: actionStr,
      customEventLabel: labelStr,
      hitCallback: function () {
        console.log("Event received");
      },
    };
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
