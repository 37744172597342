import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { PeelHaltonListingsSearchService } from "./peelhalton-listings-search.service";
import { Listing } from "./listing";
import { Globals } from "./globals";

@Component({
  imports: [CommonModule, FormsModule],
  standalone: true,
  selector: "my-peelhalton-listing-search",
  templateUrl: "./peelhalton-listing-search.component.html",
  styleUrls: ["./listing-search.component.css"],
  providers: [PeelHaltonListingsSearchService],
})
export class PeelHaltonListingsSearchComponent implements OnInit {
  @Output() filterListings = new EventEmitter<Object>();
  public showFiltersBln = false;
  public filterHTMLStr: string = "+ Show Filters";
  model = {
    searchType: { value: "Not Specified" },
    area: { value: "" },
    priceRangeMin: { value: "1000000" },
    priceRangeMax: { value: "100000000" },
    bedrooms: { value: "Any" },
    bathrooms: { value: "Any" },
    lotSize: { value: "Any" },
    squareFeet: { value: "Any" },
    yearBuilt: { value: "All" },
  };
  areas: Object[] = [];

  private searchArea = "test";

  constructor(
    private peelHaltonListingsSearchService: PeelHaltonListingsSearchService,
    private router: Router,
    private globals: Globals,
  ) {
    console.log("loading toronto search bar");
    // define properties
    var propertyTypeStr = globals.propertyTypeStr;
    var cityStr = globals.districtList;
    var priceRangeMin = "1000000";
    var priceRangeMax = globals.searchMax;
    var bedroomsNum = globals.bedroomsNum;
    var bathroomsNum = globals.bathroomsNum;
    var squareFeetStr = globals.squareFeetStr;
    if (propertyTypeStr != "") this.model.searchType.value = propertyTypeStr;
    if (cityStr != "") this.model.area.value = cityStr;
    if (priceRangeMin != "") this.model.priceRangeMin.value = priceRangeMin;
    if (priceRangeMax != "") this.model.priceRangeMax.value = priceRangeMax;
    if (bedroomsNum != "") this.model.bedrooms.value = bedroomsNum;
    if (bathroomsNum != "") this.model.bathrooms.value = bathroomsNum;
    if (squareFeetStr != "") this.model.squareFeet.value = squareFeetStr;
    console.log("Area Selected: " + this.model.area.value);
  }

  search(): void {
    // Push a search term into the observable stream.
    //this.searchTerms.next(term);
    //alert(1);
    this.filterListings.emit(this.model);
  }

  ngOnInit(): void {
    /*this.listings = this.searchTerms
        .debounceTime(300)        // wait for 300ms pause in events
        .distinctUntilChanged()   // ignore if next search term is same as previous
        .switchMap(term => term   // switch to new observable each time
            // return the http search observable
            ? this.listingsSearchService.search(term)
            // or the observable of empty heroes if no search term
            : Observable.of<Listing[]>([]))
        .catch(error => {
            // TODO: real error handling
            console.log(`Error in component ... ${error}`);
            return Observable.of<Listing[]>([]);
        });*/
  }

  updateFilters(): void {
    // set the boolean indicator whether or not show the expanded filters
    this.showFiltersBln = this.showFiltersBln ? false : true;
    // change the HTML for the show filters/hide filters button
    if (!this.showFiltersBln) this.filterHTMLStr = "+ Show Filters";
    else this.filterHTMLStr = "- Hide Filters";
  }

  gotoDetail(listing: Listing): void {
    const link = ["/detail", listing.id];
    this.router.navigate(link);
  }
}
