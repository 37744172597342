<div *ngIf="loadedBln"
     infiniteScroll
     [immediateCheck]="true"
     [fromRoot]="true"
     [infiniteScrollDistance]="2"
     [infiniteScrollUpDistance]="5"
     [infiniteScrollThrottle]="0"
     (scrolled)="onScrollDown()"
     (scrolledUp)="onScrollUp()"
     [scrollWindow]="true">
  <!-- Navigation -->
  <nav class="navbar navbar-default sticky-top">
    <my-navigation></my-navigation>
  </nav>
  <div class="nav-bg"></div>
  <div id="top"></div>

  <!--<div class="contact-cta">
    <div class="inner-wrapper">
      <h3>Contact</h3>
      <p>Donna Gray: <a href="tel:4162760632">416.276.0632</a></p>
    </div>
  </div>-->
  <!-- Header -->
  <div *ngIf="selectedListing!=undefined">
  <span *ngIf="currentScreenSize=='Small' || currentScreenSize=='Medium'">
        <header [style.background-image]="getBackground(selectedListing.thumbnailArr[0])">
            <div class="container">
                <div class="intro-text">
                    <div class="intro-lead-in">Listed For {{ selectedListing.listingPriceFullStr }}</div>
                    <div class="intro-heading" *ngIf="selectedListing.sourceStr!='TREB'">{{ selectedListing.addressStr }}</div>
                    <div class="intro-heading" *ngIf="selectedListing.sourceStr=='TREB'">{{ selectedListing.communityStr }}</div>


                    <div *ngIf="selectedListing.virtualTourStr!=''">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.virtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">Virtual
                            Tour</a>
                    </div>
                    <a *ngIf="selectedListing.statusStr!='Rental'" href="javascript:;" [ngxScrollTo]="'gallery'" class="page-scroll btn btn-xl">View More</a>
                    <a *ngIf="selectedListing.statusStr=='Rental'" href="mailto:greg@caymanmarshall.com?subject=Booking for {{selectedListing.MLSNum}} - {{selectedListing.addressStr}}" class="page-scroll btn btn-xl">Book It!</a>
                </div>
            </div>
        </header>
    </span>

    <span *ngIf="currentScreenSize=='Large' || currentScreenSize=='XLarge'">
        <header [style.background-image]="getBackground(selectedListing.imageArr[0])">
            <div class="container">
                <div class="intro-text">
                    <div class="intro-lead-in">Listed For {{ selectedListing.listingPriceFullStr }}</div>
                    <div class="intro-heading" *ngIf="selectedListing.sourceStr!='TREB'">{{ selectedListing.addressStr }}</div>
                    <div class="intro-heading" *ngIf="selectedListing.sourceStr=='TREB'">{{ selectedListing.communityStr }}</div>


                    <div *ngIf="selectedListing.virtualTourStr!=''">
                        <a target="_blank" class="page-scroll btn btn-xl" href="{{selectedListing.virtualTourStr}}" style="color:red; border-color: red; padding: 20px 27px;">Virtual
                            Tour</a>
                    </div>
                    <a *ngIf="selectedListing.statusStr!='Rental'" href="javascript:;" [ngxScrollTo]="'gallery'" class="page-scroll btn btn-xl">View More</a>
                    <a *ngIf="selectedListing.statusStr=='Rental'" href="mailto:greg@caymanmarshall.com?subject=Booking for {{selectedListing.MLSNum}} - {{selectedListing.addressStr}}" class="page-scroll btn btn-xl">Book It!</a>
                </div>
            </div>
        </header>
    </span>

    <!-- gallery Grid Section -->
    <section id="gallery" class="bg-light-gray">
      <div class="container">
        <div class="sub-nav">
          <my-navigation-details-full [selectedListing]="selectedListing"></my-navigation-details-full>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">Gallery</h2>

            <h3 class="section-subheading text-muted">Explore This Beautiful Property</h3>
          </div>
        </div>

        <div class="row  column-gap-4 justify-content-center">

          <div *ngFor="let image of selectedListing.imageArr; let i = index;" class="col-md-4 col-sm-5 gallery-img" style="width: 380px; height: 219px;">
            <a href="{{image}}" class="" data-lightbox="cm-gallery">
              <img src="{{selectedListing.thumbnailArr[i]}}" class="img-responsive" alt="" style="width: 380px; height: 219px;"></a>
          </div>


        </div>
      </div>
    </section>

    <!-- Details Section -->
    <section id="details">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">

            <h2 class="section-heading">Details</h2>

            <h3 class="" *ngIf="selectedListing.sourceStr!='TREB'">{{ selectedListing.addressStr }}</h3>
            <h3 class="" *ngIf="selectedListing.sourceStr=='TREB'">{{ selectedListing.communityStr }}</h3>
            <h4 class="subtext">Listed For {{ selectedListing.listingPriceFullStr }}</h4><h4 class="">
            {{ selectedListing.cityStr }}</h4>

            <h5 class="bottom-push">
              <span class="text-muted thin">Status</span> <span *ngIf="selectedListing.statusStr=='A' || selectedListing.statusStr=='Active' || selectedListing.statusStr=='ACTIVE'">Active</span></h5>

            <div class="row">

              <div class="col-md-2 col-md-offset-2">
                <h2 class="section-heading text-muted">
                  {{ selectedListing.bedroomsNum }}</h2>

                <h3 class="section-subtext">Bedrooms</h3>
              </div>
              <div class="col-md-2">
                <h2 class="section-heading text-muted">
                  {{ selectedListing.bathroomsFullNum }}</h2>
                <h3 class="section-subtext">Bathrooms</h3>
              </div>

              <span *ngIf="(selectedListing.statusStr | lowercase)!='rental'" class="row">
                            <div class="col-md-2" *ngIf="selectedListing.sourceStr!='TREB'">
                                <h2 class="section-heading text-muted">{{ selectedListing.lotSizeStr }}</h2>
                                <h3 class="section-subtext">Acres</h3>
                            </div>
                            <div class="col-md-2">
                                <h2 class="section-heading text-muted">{{ selectedListing.squareFeetStr }}</h2>

                                <h3 class="section-subtext">Sq. Ft.</h3>
                            </div>
                        </span>

            </div>

            <h3 class="top-push">Property Description</h3>

            <p class="top-push" [innerHTML]="selectedListing.descriptionStr"></p>

            <p class="top-push">Listing courtesy of {{ selectedListing.listingOfficeNameStr }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">

          </div>
        </div>
      </div>
    </section>

    <!-- Contact Section -->
    <section id="contact">
      <div class="container">
        <contact-form [selectedListing]="selectedListing"></contact-form>
      </div>
    </section>
  </div>

  <my-footer></my-footer>
</div>
