import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { Listing } from "./listing";
import { ListingDetail } from "./listing-detail";
import { ListingsService } from "./listings.service";
import { SEOService } from "./seo.service";
import { ListingsSearchComponent } from "./listings-search.component";
import { NavigationComponent } from "./navigation.component";

import { Globals } from "./globals";

import { NgbdCarouselConfig } from "./carousel-config";
import { CommonModule } from "@angular/common";

declare let ga: Function;

@Component({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    ListingsSearchComponent,
    NavigationComponent,
    RouterModule,
    NgbdCarouselConfig,
  ],
  standalone: true,
  selector: "my-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements AfterViewInit, OnInit {
  private trackingURLStr = "/classes/REP_RETSTracking.php"; // URL to web api
  listings: Listing[] = [];
  @Input() selectedListing: ListingDetail | undefined;
  @Input() mynumber: any;
  countNum: number = 0;
  public isSelectedBln: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private listingsService: ListingsService,
    private SEOService: SEOService,
    public globals: Globals,
  ) {
    //console.log('hello');
    // set the globals district list
    globals.districtList = "ALL OF MUSKOKA";
    // set the SEO details
    SEOService.setMetaTags({
      _titleStr:
        "Peel and Halton Luxury Real Estate - Cayman Marshall International Realty Inc.",
      _descriptionStr:
        "Search all Luxury Peel and Halton real estate listings, cottages, homes and land - Peel and Halton Luxury Listing Agent",
      _urlStr: "https://www.oakvillehomerealestate.net/" + router.url,
      _keywordsStr:
        "Oakville luxury real estate, Mississauga luxury real estate,Peel and Halton luxury real estate, Peel and Halton luxury homes, Peel and Halton luxury single family homes, Peel and Halton luxury property, Peel and Halton property, Peel and Halton waterfront",
    });
  }

  ngOnInit(): void {
    console.log("init Dashboard");
    console.log("Route: " + this.router);
    console.log("Path: " + this.router.url);
    console.log("Global Status Before: " + this.globals.statusStr);
    // reconfigure the URL to be read for status types
    let url: string = this.router.url.substring(
      0,
      this.router.url.indexOf("?"),
    );
    if (url == "") url = this.router.url;
    console.log("Cleaned Path: " + url);

    //console.log(this.listings);
    //this.resetPaging();
    if (url == "/sold" || url == "/search/sold")
      this.globals.statusStr = "Sold";
    else if (url == "/rentals" || url == "/search/rentals")
      this.globals.statusStr = "Rental";
    else this.globals.statusStr = '"Active", "Sold"';
    console.log("Global Status After: " + this.globals.statusStr);
    // fork, if the url has changed from active to sold or rental then reload the listings
    if (
      this.globals.previousStatusStr != "" &&
      this.globals.previousStatusStr != this.globals.statusStr
    ) {
      this.resetPaging();
      this.globals.previousStatusStr = this.globals.statusStr;
    } else {
      this.globals.previousStatusStr = this.globals.statusStr;
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.districtList == "TORONTO") {
      this.globals.districtList = "ALL OF MUSKOKA";
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.stateStr == "Ontario") {
      this.globals.stateStr = "ON";
    }
    // fork, this is a rental search then make sure to reset the minimum
    if (this.globals.statusStr == "Rental") {
      this.globals.searchMin = "0";
    }
    // fork, if the listings haven't been already loaded then load them up
    if (this.globals.listings.length == 0) {
      console.log("loading listings");
      this.listingsService.getListings().subscribe(
        (response: any) => {
          var dataObj = response as any;
          var listings = dataObj.dataArr as Listing[];
          var countNum = dataObj.countNum;
          this.globals.countNum = countNum;
          this.listings = listings;
          this.countNum = this.globals.countNum;
          this.globals.listings = listings;
          console.log("listings array");
          console.log(listings);
          // track the event
          this.trackEvent({ _eventStr: "search" });
          // fork, if there are any listings to be displayed then display then load the first liting
          if (this.countNum > 0) this.gotoDetail(listings[0]);
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
    } else {
      this.listings = this.globals.listings;
      this.selectedListing = this.globals.selectedListing;
      this.isSelectedBln = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setScrollTo("resultsList"));
  }

  gotoDetail(listing: Listing): void {
    //console.log('detail indirect');
    // reconfigure the URL to be read for status types
    let url: string = this.router.url.substring(
      0,
      this.router.url.indexOf("?"),
    );
    this.globals.previousURLStr = url;
    this.listingsService
      .getListing(listing.MLSNum)
      //.then(listingsDetails => this.selectedListing = listingsDetails);
      .subscribe(
        (response: any) => {
          var listingsDetails = response as any;
          this.selectedListing = listingsDetails as ListingDetail;
          this.isSelectedBln = true;
          this.globals.selectedListing = listingsDetails;
          console.log("Selected Listing: ");
          console.log(this.isSelectedBln);
          // track the event
          this.trackEvent({ _eventStr: "preview" });
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
  }

  gotoDetailDirect(listing: Listing): void {
    console.log("gotoDetailDirect");
    console.log(listing);
    // define properties
    var addressHyphenStr = listing.addressHyphenStr;
    var MLSNum = listing.MLSNum;
    var cityHyphenStr = listing.cityHyphenStr;
    console.log(
      "url: " +
        "search/fulldetails/" +
        addressHyphenStr +
        "/" +
        cityHyphenStr +
        "/" +
        MLSNum,
    );
    // reconfigure the URL to be read for status types
    let url: string = this.router.url.substring(
      0,
      this.router.url.indexOf("?"),
    );
    console.log(listing);
    var URLStr =
      "https://m.caymanmarshall.com/search/" +
      addressHyphenStr +
      "/res/" +
      MLSNum;
    this.globals.previousURLStr = url;
    //window.location.href=URLStr;
    if (cityHyphenStr != "")
      this.router.navigateByUrl(
        "search/fulldetails/" +
          addressHyphenStr +
          "/" +
          cityHyphenStr +
          "/" +
          MLSNum,
      );
    else
      this.router.navigateByUrl(
        "search/fulldetails/" + addressHyphenStr + "/" + MLSNum,
      );
  }

  filterListings(searchObj: any) {
    //reset the paging
    this.resetPaging();
    // update the globe search properties
    this.updateGlobalFilterSettings(searchObj);
    // upate the listings
    this.listingsService
      .filterListings(searchObj)
      .subscribe((response: any) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        // track the event
        this.trackEvent({ _eventStr: "search" });
      });
  }

  setScrollTo(nodeIdStr: string) {
    let node = document.getElementById(nodeIdStr);
    // fork, if this is not a mobile version then scroll using the results list or else scroll using the window
    if (!this.globals.mobileBln) {
      if (node) {
        console.log("scroll to ", this.globals.scrollTopNum);
        node.scrollTop = this.globals.scrollTopNum;
      }
    } else {
      console.log("scroll mobile to ", this.globals.scrollTopNum);
      window.scrollTo(0, this.globals.scrollTopNum);
    }
  }

  public onScrollDown() {
    //console.log('scrolled down!!');
    if (this.globals.loadingBln) return;
    if (this.listings.length >= this.globals.countNum) return;
    this.globals.loadingBln = true;
    this.listingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUp() {
    //console.log('scrolled up!!');
  }

  public onScrollDownGlobal() {
    //console.log('scrolled down!!');
    if (this.globals.loadingBln) return;
    this.globals.loadingBln = true;
    this.listingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUpGlobal() {
    //console.log('scrolled up!!');
  }

  public resetPaging() {
    this.globals.limitNum = 10;
    this.globals.startNum = 0;
    this.globals.endNum = 10;
    this.globals.countNum = 0;
    this.listings = [];
    this.globals.listings = [];
  }

  public updateGlobalFilterSettings(searchObj: any) {
    this.globals.propertyTypeStr = searchObj.searchType.value;
    this.globals.districtList = searchObj.area.value;
    this.globals.searchMin = searchObj.priceRangeMin.value;
    this.globals.searchMax = searchObj.priceRangeMax.value;
    this.globals.bedroomsNum = searchObj.bedrooms.value;
    this.globals.bathroomsNum = searchObj.bathrooms.value;
    this.globals.squareFeetStr = searchObj.squareFeet.value;
  }

  public trackEvent(obj: { _eventStr: any }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.generateEventTrackingData(obj),
    };
    // track the even
    this.http.post(this.trackingURLStr, options).subscribe(
      (response) => {
        //console.log(response.json());
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  private generateEventTrackingData(obj: { _eventStr: any }) {
    // define properties
    var emailStr = this.globals.emailStr;
    var userIDStr = this.globals.userIDStr;
    var ipAddrStr = this.globals.ipAddrStr;
    var sidStr = this.globals.sidStr;
    var idStr = this.globals.idStr;
    var eventStr = obj._eventStr;
    var eventFullStr =
      eventStr == "preview"
        ? this.generatePreviewEventFullString()
        : this.generateEventFullString();
    // define properties
    var JSONObj = {
      idStr: idStr,
      emailStr: "",
      userIDStr: "",
      ipAddrStr: ipAddrStr,
      sessionIDStr: "",
      eventStr: eventStr,
      eventFullStr: eventFullStr,
      viewStr: "list-view",
    };
    if (emailStr != "") JSONObj.emailStr = emailStr;
    else delete (JSONObj as any)["emailStr"];
    if (userIDStr != "") JSONObj.userIDStr = userIDStr;
    else delete (JSONObj as any)["userIDStr"];
    if (sidStr != "") JSONObj.sessionIDStr = sidStr;
    else delete (JSONObj as any)["sessionIDStr"];
    // track the analtics event
    this.trackAnalyticsEvent({
      _catStr: "RETS",
      _eventStr: eventStr,
      _labelStr: eventFullStr,
    });
    return JSONObj;
  }

  private generateEventFullString() {
    // define properties
    var cityStr = this.globals.districtList;
    var stateStr = this.globals.stateStr;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var bedroomsNum = this.globals.bedroomsNum;
    var bathroomsNum = this.globals.bathroomsNum;
    var squareFeetStr = this.globals.squareFeetStr;
    var searchMinNum = this.globals.searchMin;
    var searchMaxNum = this.globals.searchMax;
    var eventFullObj = {
      cityStr: cityStr,
      stateStr: stateStr,
      propertyTypeStr: propertyTypeStr,
      bedroomsNum: bedroomsNum,
      bathroomsNum: bathroomsNum,
      squareFeetStr: squareFeetStr,
      searchMin: searchMinNum,
      searchMax: searchMaxNum,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private generatePreviewEventFullString() {
    if (this.selectedListing == undefined) return;
    // define properties
    var MLSNum = this.selectedListing.MLSNum;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var eventFullObj = {
      MLSNum: MLSNum,
      propertyTypeStr: propertyTypeStr,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  private trackAnalyticsEvent(obj: {
    _catStr: any;
    _eventStr: any;
    _labelStr: any;
  }) {
    if (this.selectedListing == undefined) return;
    // define properties
    var catStr = obj._catStr;
    var eventStr = obj._eventStr;
    var labelStr = obj._labelStr;
    var actionStr = "Search Parameter Change";
    // fork, if this is a preview  then set the event action accordingly
    if (eventStr == "preview")
      actionStr = "Preview Listing | " + this.selectedListing.addressStr;
    (<any>window).dataLayer.push({
      event: "custom.event.preview",
      customEventCategory: catStr,
      customEventAction: actionStr,
      customEventLabel: labelStr,
      hitCallback: function () {
        console.log("Event received");
      },
    });
    /*ga('send', {
     hitType: 'event',
     eventCategory: catStr,
     eventAction: actionStr,
     eventLabel: labelStr,
     'hitCallback' : function () {
     //alert("Event received");
     }
     });*/
  }
}
