<ul *ngIf="selectedListing!=undefined" class="nav navbar-nav navbar-center list-group list-group-horizontal m-4">
  <li *ngIf="currentScreenSize=='Small' || currentScreenSize=='Medium'" [ngClass]="{active: activeStr==='top'}"><a class="p-3 text-decoration-none" href="javascript:;" (click)="goBack(); collapseMenu()">Home</a></li>
  <li *ngIf="currentScreenSize=='Large' || currentScreenSize=='XLarge'" [ngClass]="{active: activeStr==='top'}"><a class="p-3 text-decoration-none" href="javascript:;" (click)="goBackDirect(); collapseMenu()">Home</a></li>
  <li [ngClass]="{active: activeStr==='gallery'} "><a class="p-3 text-decoration-none" href="javascript:;" (click)="trackAnalyticsEvent({_eventStr: 'gallery-click'}); collapseMenu()" [ngxScrollTo]="'gallery'">Gallery</a></li>
  <li [ngClass]="{active: activeStr==='details'}"><a class="p-3 text-decoration-none" href="javascript:;" (click)="trackAnalyticsEvent({_eventStr: 'details-click'}); collapseMenu()" [ngxScrollTo]="'details'">Details</a></li>
  <li [ngClass]="{active: activeStr==='contact'}"><a class="p-3 text-decoration-none" href="javascript:;" (click)="trackAnalyticsEvent({_eventStr: 'request-more-info-click'}); collapseMenu()" [ngxScrollTo]="'contact'">Request More Info</a></li>
  <li *ngIf="selectedListing.virtualTourStr">
    <a class="p-3 text-decoration-none" href="{{selectedListing.virtualTourStr}}" target="_blank">Virtual Tour</a>
  </li>
</ul>
<!-- /.navbar-collapse -->
