import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { FormsModule } from "@angular/forms";

import { ListingsSearchService } from "./listings-search.service";
import { Listing } from "./listing";
import { Globals } from "./globals";

@Component({
  imports: [CommonModule, FormsModule],
  standalone: true,
  selector: "my-listing-search",
  templateUrl: "./listing-search.component.html",
  styleUrls: ["./listing-search.component.css"],
  providers: [ListingsSearchService],
})
export class ListingsSearchComponent implements OnInit {
  @Output() filterListings = new EventEmitter<Object>();
  public showFiltersBln = false;
  public filterHTMLStr: string = "+ Show Filters";
  model = {
    searchType: { value: "Not Specified" },
    area: { value: "ALL OF MUSKOKA" },
    priceRangeMin: { value: "125000" },
    priceRangeMax: { value: "100000000" },
    bedrooms: { value: "Any" },
    bathrooms: { value: "Any" },
    lotSize: { value: "Any" },
    squareFeet: { value: "Any" },
    yearBuilt: { value: "All" },
  };
  areas: Array<any> = [
    { name: "Lake Muskoka", value: "LAKE MUSKOKA" },
    { name: "Lake Rosseau", value: "LAKE ROSSEAU" },
    { name: "Lake Joseph", value: "LAKE JOSEPH" },
    { name: "Lake Of Bays", value: "LAKE OF BAYS" },
    { name: "Smaller quiet Lakes", value: "SMALLER QUIET LAKES" },
    { name: "Lake Mary", value: "LAKE MARY" },
    { name: "Huntsville", value: "HUNTSVILLE" },
    { name: "Bracebridge", value: "BRACEBRIDGE" },
    { name: "Gravenhurst", value: "GRAVENHURST" },
    { name: "Bala", value: "BALA" },
    { name: "Port Carling", value: "PORT CARLING" },
    { name: "Georgian Bay", value: "GEORGIAN BAY" },
  ];

  private searchArea = "test";

  constructor(
    private listingsSearchService: ListingsSearchService,
    private router: Router,
    private globals: Globals,
  ) {
    console.log("loading muskoka search bar");
    // define properties
    var propertyTypeStr = globals.propertyTypeStr;
    var cityStr = globals.districtList;
    var priceRangeMin = globals.searchMin;
    var priceRangeMax = globals.searchMax;
    var bedroomsNum = globals.bedroomsNum;
    var bathroomsNum = globals.bathroomsNum;
    var squareFeetStr = globals.squareFeetStr;
    if (propertyTypeStr != "") this.model.searchType.value = propertyTypeStr;
    if (cityStr != "") this.model.area.value = cityStr;
    if (priceRangeMin != "") this.model.priceRangeMin.value = priceRangeMin;
    if (priceRangeMax != "") this.model.priceRangeMax.value = priceRangeMax;
    if (bedroomsNum != "") this.model.bedrooms.value = bedroomsNum;
    if (bathroomsNum != "") this.model.bathrooms.value = bathroomsNum;
    if (squareFeetStr != "") this.model.squareFeet.value = squareFeetStr;
    console.log(this.model);
  }

  search(): void {
    // Push a search term into the observable stream.
    //this.searchTerms.next(term);
    //alert(1);
    this.filterListings.emit(this.model);
  }

  ngOnInit(): void {
    /*this.listings = this.searchTerms
        .debounceTime(300)        // wait for 300ms pause in events
        .distinctUntilChanged()   // ignore if next search term is same as previous
        .switchMap(term => term   // switch to new observable each time
            // return the http search observable
            ? this.listingsSearchService.search(term)
            // or the observable of empty heroes if no search term
            : Observable.of<Listing[]>([]))
        .catch(error => {
            // TODO: real error handling
            console.log(`Error in component ... ${error}`);
            return Observable.of<Listing[]>([]);
        });*/
  }

  updateFilters(): void {
    // set the boolean indicator whether or not show the expanded filters
    this.showFiltersBln = this.showFiltersBln ? false : true;
    // change the HTML for the show filters/hide filters button
    if (!this.showFiltersBln) this.filterHTMLStr = "+ Show Filters";
    else this.filterHTMLStr = "- Hide Filters";
  }

  gotoDetail(listing: Listing): void {
    const link = ["/detail", listing.id];
    this.router.navigate(link);
  }
}
