import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { Listing } from "./listing";
import { ListingDetail } from "./listing-detail";
import { PeelHaltonListingsService } from "./peelhalton-listings.service";
import { TorontoListingsService } from "./toronto-listings.service";
import { SEOService } from "./seo.service";
import { ModalContentComponent } from "./modal-content.component";
import { NavigationComponent } from "./navigation.component";
import { PeelHaltonListingsSearchComponent } from "./peelhalton-listings-search.component";

import { Globals } from "./globals";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

// GA4 Analytics Tracking
import { DataLayerService } from "./data-layer.service";

import { NgbdCarouselConfig } from "./carousel-config";
import { CommonModule } from "@angular/common";
import { Subject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";

declare let ga: Function;

@Component({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    PeelHaltonListingsSearchComponent,
    NavigationComponent,
    RouterModule,
    NgbdCarouselConfig,
  ],
  standalone: true,
  selector: "my-peelhalton-dashboard",
  templateUrl: "./peelhalton-dashboard.component.html",
  styleUrls: ["./peelhalton-dashboard.component.css"],
})
export class PeelHaltonDashboardComponent implements AfterViewInit, OnInit {
  private trackingURLStr = "/classes/REP_RETSTracking.php"; // URL to web api
  listings: Listing[] = [];
  @Input() selectedListing: ListingDetail | undefined;
  @Input() mynumber: any;
  countNum: number = 0;
  public isSelectedBln: boolean = false;
  public listingsViewedNum = 0;
  public isRegisteredBln = false;
  bsModalRef: BsModalRef | undefined;
  public subscriptions: any[] = [];
  public messages: string[] = [];
  public step2Bln = false;
  destroyed = new Subject<void>();
  currentScreenSize: string | undefined;

  // Create a map to display breakpoint names for demonstration purposes.
  displayNameMap = new Map([
    [Breakpoints.XSmall, "XSmall"],
    [Breakpoints.Small, "Small"],
    [Breakpoints.Medium, "Medium"],
    [Breakpoints.Large, "Large"],
    [Breakpoints.XLarge, "XLarge"],
  ]);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private renderer: Renderer2,
    private peelHaltonListingsService: PeelHaltonListingsService,
    private torontoListingsService: TorontoListingsService,
    private SEOService: SEOService,
    public globals: Globals,
    private elementRef: ElementRef,
    private modalService: BsModalService,
    private _dataLayerService: DataLayerService,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize =
              this.displayNameMap.get(query) ?? "Unknown";
            console.log("screen size: " + this.currentScreenSize);
          }
        }
      });
    //console.log('hello');
    var titleStr =
      "Peel and Halton Luxury Real Estate - South Cayman Marshall International Realty Inc.";

    // switch, based on the user defined url set the title for SEO
    switch (globals.hostnameStr) {
      case "oakvillesluxuryrealestate.com":
        titleStr =
          "Oakville Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "mississaugasluxuryrealestate.com":
        titleStr =
          "Mississauga Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "burlingtonluxuryrealestate.com":
        titleStr =
          "Burlington Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "southcaymanmarshall.com":
        titleStr =
          "Mississauga Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "portcreditluxuryrealestate.com":
        titleStr =
          "Port Credit Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "mineolaluxuryrealestate.com":
        titleStr =
          "Mineola Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
      case "lorneparkluxuryrealestate.ca":
        titleStr =
          "Lorne Park Luxury Real Estate - Cayman Marshall International Realty Inc.";
        break;
    }
    // fork, if this is a an oakville base hostname visit then we need to disable registration
    if (globals.hostnameStr == "oakvillehomerealestate.net")
      globals.isRegisteredBln = "1";
    // set the SEO details
    SEOService.setMetaTags({
      _titleStr: titleStr,
      _descriptionStr:
        "Search all Luxury Peel and Halton real estate listings, cottages, homes and land - Peel and Halton Luxury Listing Agent",
      _urlStr: "https://www." + globals.hostnameStr + router.url,
      _keywordsStr:
        "Oakville luxury real estate, Mississauga luxury real estate,Peel and Halton luxury real estate, Peel and Halton luxury homes, Peel and Halton luxury single family homes, Peel and Halton luxury property, Peel and Halton property, Peel and Halton waterfront",
    });
  }

  ngOnInit(): void {
    console.log("init Peel Halton Dashboard");
    console.log("Path: " + this.router.url);
    console.log(this.globals.statusStr);
    // define properties
    var routerURLStr = this.router.url;
    //console.log(this.listings);
    if (this.currentScreenSize == "Large" || this.currentScreenSize == "XLarge")
      this.renderer.setStyle(document.body, "overflow", "hidden");
    //this.resetPaging();
    if (routerURLStr == "/sold" || routerURLStr == "/search/sold")
      this.globals.statusStr = "Sold";
    else if (routerURLStr == "/rentals" || routerURLStr == "/search/rentals")
      this.globals.statusStr = "Rental";
    else if (
      routerURLStr == "/toronto" ||
      routerURLStr == "/search/toronto" ||
      routerURLStr.includes("/search/toronto")
    )
      this.globals.statusStr = "Toronto";
    else this.globals.statusStr = '"Active", "Sold"';
    // fork, if the url has changed from active to sold or rental then reload the listings
    if (
      this.globals.previousStatusStr != "" &&
      this.globals.previousStatusStr != this.globals.statusStr
    ) {
      this.resetPaging();
      this.globals.previousStatusStr = this.globals.statusStr;
    } else {
      this.globals.previousStatusStr = this.globals.statusStr;
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.districtList == "ALL OF MUSKOKA") {
      this.globals.districtList = "Mississauga";
    }
    // fork, if no city has been set then set the city to toronto
    if (this.globals.stateStr == "ON") {
      this.globals.stateStr = "Ontario";
    }
    // set the global min value to 100000 as per Todd's request
    this.globals.searchMin = "1000000";
    // fork, if the listings haven't been already loaded then load them up
    if (this.globals.listings.length == 0) {
      console.log("loading listings");
      this.peelHaltonListingsService.getListings().subscribe(
        (response: any) => {
          var dataObj = response as any;
          var listings = dataObj.dataArr as Listing[];
          var countNum = dataObj.countNum;
          this.globals.countNum = countNum;
          this.listings = listings;
          this.countNum = this.globals.countNum;
          this.globals.listings = listings;
          console.log("listings array");
          console.log(listings);
          // track the event
          this.trackEvent({ _eventStr: "search" });
          // fork, if there are any listings to be displayed then display then load the first liting
          if (this.countNum > 0) this.gotoDetail(listings[0]);
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
    } else {
      this.listings = this.globals.listings;
      this.selectedListing = this.globals.selectedListing;
      this.isSelectedBln = true;
    }
    // fork, if the user has registered then set the property variable
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    // disable the registration requirment
    //this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
  }

  ngAfterViewInit(): void {
    var header = document.querySelector(".navbar-default");
    if (header != null) header.classList.remove("navbar-shrink");
    setTimeout(() => this.setScrollTo("resultsList"));
  }

  gotoDetail(listing: Listing): void {
    console.log("direct link clicked");
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
    console.log("init indicator: " + this.globals.initBln);
    console.log("Router URL" + this.router.url);
    this.globals.previousURLStr = this.router.url;
    let etobicokeArr = [
      "Etobicoke",
      "Long Branch",
      "Alderwood",
      "Mimico",
      "New Toronto",
      "Humber Bay Shores",
      "Stonegate-Queensway",
    ];
    //console.log('detail indirect');
    let etobicokeBln = etobicokeArr.includes(this.globals.districtList);
    // fork, if this is a non etobicoke search then use the peel halton db to search for the listing
    if (!etobicokeBln) {
      this.peelHaltonListingsService
        .getListing(listing.MLSNum, listing.sourceStr)
        .subscribe(
          (response: any) => {
            var listingsDetails = response as any;
            this.selectedListing = listingsDetails as ListingDetail;
            this.isSelectedBln = true;
            this.globals.selectedListing = listingsDetails;
            console.log("Selected Listing: ");
            console.log(this.isSelectedBln);
            // track the event
            //this.trackEvent({ _eventStr: "preview" });
          },
          (error: any) => {
            this.handleError(error); // Centralized error handling
          },
        );
    } else {
      this.torontoListingsService.getListing(listing.MLSNum, "TREB").subscribe(
        (response: any) => {
          var listingsDetails = response as any;
          this.selectedListing = listingsDetails as ListingDetail;
          this.isSelectedBln = true;
          this.globals.selectedListing = listingsDetails;
          console.log("Selected Listing: ");
          console.log(this.isSelectedBln);
          // track the event
          //this.trackEvent({ _eventStr: "preview" });
        },
        (error: any) => {
          this.handleError(error); // Centralized error handling
        },
      );
    }
    if (!this.globals.initBln) {
      // add to the viewed counter
      this.globals.listingsViewedNum++;
      console.log("viewed: " + this.globals.listingsViewedNum);
      //console.log("viewed: " + this.listingsViewedNum);
      // fork, if the user has viewed 2 or more listings and has not regstered then show the registration modal
      if (this.globals.listingsViewedNum >= 2 && !this.isRegisteredBln)
        this.showRegistration();
    } else {
      this.globals.initBln = false;
    }
  }

  gotoDetailDirect(listing: Listing): void {
    console.log("direct link clicked");
    if (this.globals.isRegisteredBln == "1") this.isRegisteredBln = true;
    console.log("Registration Status: " + this.isRegisteredBln);
    console.log("init indicator: " + this.globals.initBln);
    console.log("Router URL" + this.router.url);
    console.log("Listing Data");
    console.log(listing);
    console.log("Listing Data End");
    // define properties
    var addressHyphenStr = listing.addressHyphenStr;
    var MLSNum = listing.MLSNum;
    var cityHyphenStr = listing.cityStr;
    var sourceStr = listing.sourceStr;
    console.log(listing);
    var URLStr =
      "https://m.oakvillehomerealestate.net/search/" +
      addressHyphenStr +
      "/res/" +
      MLSNum;
    this.globals.previousURLStr = "search/";
    if (!this.globals.initBln) {
      // add to the viewed counter
      this.globals.listingsViewedNum++;
      console.log("viewed: " + this.globals.listingsViewedNum);
      //console.log("viewed: " + this.listingsViewedNum);
      // fork, if the user has viewed 2 or more listings and has not regstered then show the registration modal
      if (this.globals.listingsViewedNum >= 2 && !this.isRegisteredBln) {
        this.showRegistration();
        return;
      }
    } else {
      this.globals.initBln = false;
    }
    //window.location.href=URLStr;
    //this.router.navigateByUrl('fulldetails/'+MLSNum);

    //console.log('Nav To: ' +'search/fulldetails/' + addressHyphenStr + '/' + cityHyphenStr + '/' + MLSNum + '/' + sourceStr);
    this.router.navigateByUrl(
      "search/fulldetails/" +
        addressHyphenStr +
        "/" +
        cityHyphenStr +
        "/" +
        MLSNum +
        "/" +
        sourceStr,
    );
  }

  private clearSubscriptions() {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  public showRegistration() {
    console.log("showing registration");
    console.log("viewed: " + this.globals.listingsViewedNum);
    // define the config options for the modal
    var config = {
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    //this.modal.open(this.registrationModal);
    this.clearSubscriptions();
    this.subscriptions.push(
      this.modalService.onShown.subscribe((reason: string) => {
        //this.messages.push(`onShown event has been fired`);
        //console.log('shown');
      }),
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string) => {
        //this.messages.push(`onShown event has been fired`);
        //console.log('closed');
        //console.log("Modal Content"+this.bsModalRef.content.isRegisteredBln);
        // set the user registration indicator to stop the registration page from showing up again

        if (this.bsModalRef != undefined)
          this.isRegisteredBln = this.bsModalRef.content.isRegisteredBln;
        //
        //this.bsModalRef=null;
        //this.modalService.onShown=null;
        //this.registrationStepTwo();
      }),
    );
    this.bsModalRef = this.modalService.show(ModalContentComponent, config);
    this.bsModalRef.content.title = "Registration";
  }

  filterListings(searchObj: any) {
    //reset the paging
    this.resetPaging();
    // update the globe search properties
    this.updateGlobalFilterSettings(searchObj);
    // upate the listings
    this.peelHaltonListingsService
      .filterListings(searchObj)
      .subscribe((response: any) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        this.globals.countNum = dataObj.countNum;
        this.countNum = this.globals.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        // track the event
        this.trackEvent({ _eventStr: "search" });
      });
  }

  setScrollTo(nodeIdStr: string) {
    let node = document.getElementById(nodeIdStr);
    // fork, if this is not a mobile version then scroll using the results list or else scroll using the window
    if (!this.globals.mobileBln) {
      if (node) {
        console.log("scroll to ", this.globals.scrollTopNum);
        node.scrollTop = this.globals.scrollTopNum;
      }
    } else {
      console.log("scroll mobile to ", this.globals.scrollTopNum);
      window.scrollTo(0, this.globals.scrollTopNum);
    }
  }

  public onScrollDown() {
    //console.log("scrolled down!!");
    if (this.globals.loadingBln) return;
    if (this.listings.length >= this.globals.countNum) return;
    this.globals.loadingBln = true;
    this.peelHaltonListingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUp() {
    //console.log('scrolled up!!');
  }

  public onScrollDownGlobal() {
    //console.log("scrolled down global!!");
    if (this.globals.loadingBln) return;
    if (this.listings.length >= this.globals.countNum) return;
    this.globals.loadingBln = true;
    this.peelHaltonListingsService.loadMore().subscribe(
      (response) => {
        //console.log(response.json());
        console.log(response);
        var dataObj = response as any;
        var dataArr = dataObj.dataArr as Listing[];
        var countNum = dataObj.countNum;
        this.listings = this.listings.concat(dataArr);
        this.globals.loadingBln = false;
        this.globals.listings = this.listings;
        //console.log(this.globals);
        // track the event
        this.trackEvent({ _eventStr: "view more listings" });
      },
      (error) => {
        this.handleError(error); // Centralized error handling
      },
    );
  }

  public onScrollUpGlobal() {
    //console.log('scrolled up!!');
  }

  public resetPaging() {
    this.globals.limitNum = 10;
    this.globals.startNum = 0;
    this.globals.endNum = 10;
    this.globals.countNum = 0;
    this.listings = [];
    this.globals.listings = [];
  }

  public updateGlobalFilterSettings(searchObj: any) {
    this.globals.propertyTypeStr = searchObj.searchType.value;
    this.globals.districtList = searchObj.area.value;
    this.globals.searchMin = searchObj.priceRangeMin.value;
    this.globals.searchMax = searchObj.priceRangeMax.value;
    this.globals.bedroomsNum = searchObj.bedrooms.value;
    this.globals.bathroomsNum = searchObj.bathrooms.value;
    this.globals.squareFeetStr = searchObj.squareFeet.value;
  }

  public trackEvent(obj: { _eventStr: any }) {
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded;",
      "X-Requested-With": "XMLHttpRequest",
    });
    const options = {
      headers: headers,
      params: this.generateEventTrackingData(obj),
    };
    let paramsObj = this.generateEventTrackingData(obj);
    let params = new HttpParams({
      fromObject: paramsObj as any,
    });

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    var response = this.http
      .post(this.trackingURLStr, params.toString(), httpOptions)
      .subscribe();
  }

  private generateEventTrackingData(obj: { _eventStr: any }) {
    console.log(this.globals);
    // define properties
    var emailStr = this.globals.emailStr;
    var userIDStr = this.globals.userIDStr;
    var ipAddrStr = this.globals.ipAddrStr;
    var sidStr = this.globals.sidStr;
    var idStr = this.globals.idStr;
    var eventStr = obj._eventStr;
    var eventFullStr =
      eventStr == "preview"
        ? this.generatePreviewEventFullString()
        : this.generateEventFullString();
    // define properties
    var JSONObj = {
      idStr: idStr,
      emailStr: "",
      userIDStr: "",
      ipAddrStr: ipAddrStr,
      sessionIDStr: "",
      eventStr: eventStr,
      eventFullStr: eventFullStr,
      viewStr: "list-view",
    };
    if (emailStr != "") JSONObj.emailStr = emailStr;
    else delete (JSONObj as any)["emailStr"];
    if (userIDStr != "") JSONObj.userIDStr = userIDStr;
    else delete (JSONObj as any)["userIDStr"];
    if (sidStr != "") JSONObj.sessionIDStr = sidStr;
    else delete (JSONObj as any)["sessionIDStr"];
    // track the analtics event
    this.trackAnalyticsEvent({
      _catStr: "RETS",
      _eventStr: eventStr,
      _labelStr: eventFullStr,
    });
    return JSONObj;
  }

  private generateEventFullString() {
    // define properties
    var cityStr = this.globals.districtList;
    var stateStr = this.globals.stateStr;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var bedroomsNum = this.globals.bedroomsNum;
    var bathroomsNum = this.globals.bathroomsNum;
    var squareFeetStr = this.globals.squareFeetStr;
    var searchMinNum = this.globals.searchMin;
    var searchMaxNum = this.globals.searchMax;
    var eventFullObj = {
      cityStr: cityStr,
      stateStr: stateStr,
      propertyTypeStr: propertyTypeStr,
      bedroomsNum: bedroomsNum,
      bathroomsNum: bathroomsNum,
      squareFeetStr: squareFeetStr,
      searchMin: searchMinNum,
      searchMax: searchMaxNum,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private generatePreviewEventFullString() {
    // define properties
    if (this.selectedListing == undefined) return;
    var MLSNum = this.selectedListing.MLSNum;
    var propertyTypeStr = this.globals.propertyTypeStr;
    var eventFullObj = {
      MLSNum: MLSNum,
      propertyTypeStr: propertyTypeStr,
    };
    // return the json object as a string
    return JSON.stringify(eventFullObj);
  }

  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  private trackAnalyticsEvent(obj: {
    _catStr: any;
    _eventStr: any;
    _labelStr: any;
  }) {
    console.log("trackAnalyticsEvent");
    console.log(obj._eventStr);
    // define properties
    var catStr = obj._catStr;
    var eventStr = obj._eventStr;
    var labelStr = obj._labelStr;
    var actionStr = "Search Parameter Change";
    // fork, if this is a preview  then set the event action accordingly
    if (eventStr == "preview") actionStr = "Preview Listing";
    try {
      this._dataLayerService.logEvent(
        "custom.event.preview",
        catStr,
        actionStr,
        labelStr,
      );
      /*
      ga('send', {
        hitType: 'event',
        eventCategory: catStr,
        eventAction: actionStr,
        eventLabel: labelStr,
        'hitCallback' : function () {
          //alert("Event received");
        }
      });*/
    } catch (e) {
      console.log("ANALYTICS DISABLED");
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.renderer.removeStyle(document.body, "overflow");
  }
}
